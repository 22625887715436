<template>
    <div class="flex">
      <Sidebar />
      <div class="flex-1 p-6 bg-slate-700 min-h-screen text-white">
        <router-view />
      </div>
    </div>
</template>

<script>
import Sidebar from "./Sidebar.vue";

export default {
  name: "AdminPage",
  components: { Sidebar },
};
</script>

<style>
</style>