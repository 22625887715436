<template>
  <div class="h-screen flex flex-col bg-gray-900 text-white w-64">
    <div class="p-4 text-lg font-bold border-b border-gray-700">Admin Dashboard</div>
    <nav class="flex-1">
      <ul class="mt-4">
        <li 
          v-for="item in items" 
          :key="item.title"
          :class="{ 'bg-slate-800': $route.path === item.path, 'hover:bg-gray-700': $route.path !== item.path }"
          @click="toPath(item.path)" 
          class="mb-2 cursor-pointer transition-colors duration-200"
        >
          <a class="flex items-center p-2 rounded">
            <i :class="item.icon" class="text-md"></i>
            <span class="ml-3 text-[12px]">{{ item.title }}</span>
          </a>
        </li>

        <li @click="logout" class="m-3"><button class="bg-slate-600 px-4 py-1 rounded-lg">Logout</button></li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'SidebarAdmin',
  data() {
    return {
      items: [
        // { title: "Dashboard", icon: "mdi mdi-view-dashboard", path: '/admin/dashboard' },
        { title: "Users", icon: "mdi mdi-account", path: '/admin/users' },
        // { title: "Wallet", icon: "mdi mdi-wallet", path: '/admin/wallet' },
        { title: "Machine", icon: "mdi mdi-robot", path: '/admin/machine' },
        { title: "Deposit", icon: "mdi mdi-arrow-left-bold-box", path: '/admin/deposit' },
        { title: "Withdraw", icon: "mdi mdi-arrow-left-bold-box", path: '/admin/withdraw' },
        { title: "Pool Remaining", icon: "mdi mdi-arrow-left-bold-box", path: '/admin/remaining/pool' },
        { title: "Solo Remaining", icon: "mdi mdi-arrow-right-bold-box", path: '/admin/remaining/solo' },
        // { title: "Settings", icon: "mdi mdi-cog", path: '/admin/settings' },
      ],
    };
  },
  methods: {
    toPath(path) {
      this.$router.push(path);
    },
    logout() {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('_id');
      localStorage.removeItem('email');
      localStorage.removeItem('role');
      this.$router.push('/login');
    },
  }
};
</script>

<style>
/* You can add more custom styles here if needed */
</style>
