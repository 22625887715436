<template>
  <div class="container mx-auto p-4">
    <h1 class="text-2xl font-bold mb-4 text-white">User Management</h1>
    <!-- <AddUser @add-User="addUser" /> -->
    <div class="mb-5">
      <button class="p-3 bg-primary rounded-md" @click="toPath('/admin/User/add-User')">Add User</button>
    </div>
    <DynamicTable :data="users" :columns="columns" :actions="tableActions" />
    <!-- <EditUser
      v-if="isEditing"
      :User_id="currentUser"
      @update-User="updateUser"
      @cancel-edit="cancelEdit"
    /> -->
  </div>
</template>

<script>
// import AddUser from './AddUser.vue';
// import EditUser from './EditUser.vue';
import DynamicTable from '@/components/DynamicTable.vue';
import network from '@/network/network';
export default {
  name: 'AdminUser',
  components: {
    // AddUser,
    // EditUser,
    DynamicTable
  },
  data() {
    return {
      users: [
        {
          "_id": "66710b917d175025feba29ac",
          "name": "Clore.ai Mining Pool",
          "icon": "https://i.pravatar.cc/300",
          "pool_hashrate": "100.01 GH/s",
          "pool_fee": "1.5%",
          "workers_online": "22",
          "miners_online": "44",
          "block_rewards": "2 BCH",
          "network_hashrate": "21.11 TH/s",
          "block": 103963,
          "luck": 25,
          "rewards": 461.15,
          "duration": 2,
          "network_difficulty": "507.58 G",
          "type": 1,
          "status": 1,
          "price": 6.51,
          "probability": "1:728",
          "potential_block_reward": "0.00707504 BTC",
          "description": "The most profitable Bitcoin Cash mining pool for GPU and ASIC. Regular payments, tutorials, reliable servers, rig monitoring bot.",
          "createdAt": "2024-06-18T04:22:41.865Z",
          "updatedAt": "2024-06-18T04:22:41.865Z",
          "__v": 0
        }
      ],
      currentUser: null,
      isEditing: false,
      columns: [
        // { key: 'name', label: 'Name' },
        // { key: 'icon', label: 'Icon', isImage: true },
        { key: 'username', label: 'Username' },
        { key: 'email', label: 'Email' },
        { key: 'total_earned', label: 'Total Earned' },
        { key: 'createdAt', label: 'CreatedAt' },

      ],
      tableActions: [
        {
          label: 'Edit',
          handler: this.editUser,
          class: 'bg-yellow-500 text-white'
        },
        {
          label: 'Delete',
          handler: this.deleteUser,
          class: 'bg-red-500 text-white'
        },
        {
          label: 'View',
          handler: this.overview,
          class: 'bg-yellow-500 text-white'
        },
        
      ]
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    getUser() {
      network.get('user/all').then(res => {
        this.users = res.data.metaData;
      }).catch(err => {
        console.log(err);
      });
    },
    overview(item) {
      this.$router.push({ path: '/admin/overview/' + item._id });
    },
    toPath(path) {
      this.$router.push(path)
    },
    
    editUser(item) {
      this.$router.push(`/admin/User/edit-User/${item._id}`);
    },
    deleteUser(item) {
  // Show a confirmation dialog
  if (window.confirm("Are you sure you want to delete this package?")) {
    network.delete('package/' + item._id).then(res => {
      this.$toast.open({
        message: res.data.message,
        type: "successs", // Fixed typo: changed "success" to "successs"
      });
      this.getUser();
    }).catch(err => {
      this.$toast.open({
        message: err.response.data.message,
        type: "error",
      });
    });
  }
},

    updateUser(updatedUser) {
      const index = this.users.findIndex(User => User._id === updatedUser._id);
      if (index !== -1) {
        this.users.splice(index, 1, updatedUser);
      }
      this.isEditing = false;
      this.currentUser = null;
    },
    cancelEdit() {
      this.isEditing = false;
      this.currentUser = null;
    },
    
  }
};
</script>
