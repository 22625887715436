<template>
  <div class="w-[100%] flex justify-center mx-auto ">
    <Line ref="lineChart" :data="chartData" :options="chartOptions" class="h-[20vh]" />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip } from 'chart.js';
import { Line } from 'vue-chartjs';
import network from '@/network/network';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip);

export default {
  name: 'BeautifulLineChart',
  props: {
    idOverview: {
      type: String,
    }
  },
  components: {
    Line
  },
  setup(props) {
    const lineChartRef = ref(null);
    const chartData = ref({
      labels: [],
      datasets: [
        {
          label: 'total amount',
          backgroundColor: '#c319ee',
          borderColor: '#c319ee',
          tension: 0.3,
          pointRadius: 0,
          data: []
        }
      ]
    });

    const chartOptions = {
      interaction: {
        intersect: false
      },
      scales: {
        y: {
          ticks: {
            color: '#b6baca'
          },
          grid: {
            drawTicks: false
          },
          border: {
            dash: [5, 10]
          }
        },
        x: {
          ticks: {
            color: '#b6baca'
          },
          grid: {
            display: false
          },
          border: {
            display: false
          }
        }
      }
    };

    const legendValues = [
      { label: 'total amount', color: '#c319ee' }
    ];

    const getDataChart = async () => {
      try {
        const res = await network.get('user/chart/' + (props.idOverview ?? localStorage.getItem('_id')));
        const data = res.data.metaData;
        chartData.value = {
          labels: data.map(d => d.date).reverse(),
          datasets: [
            {
              label: 'total amount',
              backgroundColor: '#c319ee',
              borderColor: '#c319ee',
              tension: 0.3,
              pointRadius: 0,
              data: data.map(d => d.total_amount).reverse()
            }
          ]
        };
        if (lineChartRef.value && lineChartRef.value.chartInstance) {
          lineChartRef.value.chartInstance.update();
        }
      } catch (err) {
        console.log(err);
      }
    };

    onMounted(() => {
      getDataChart();
    });

    return {
      lineChartRef,
      chartData,
      chartOptions,
      legendValues
    };
  }
};
</script>

<style scoped>
.legend-container {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  width: 10%;
  gap: 1rem;
  justify-content: center;
}
.badge {
  border-radius: 0.45rem;
  padding: 0;
  width: 0.5rem;
  height: 0.5rem;
  display: inline-block;
  margin-right: 3px;
}
</style>
