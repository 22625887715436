<template>
  <div class="flex h-[100vh] auth bg-[#0C1035]">
    <!-- <div class="tool-bar md:w-[50%] w-[98%] mx-5 rounded-[2rem] border-2 border-input_login border-gradient-to-br items-center justify-between  absolute md:translate-x-[50%] -translate-x-1 flex backdrop-blur-md mt-[2.4rem] h-[7rem] p-[2.4rem]">
      <div>
        <h1>VISION UI FREE</h1>
      </div>
      <div class="">
        <ul class="grid grid-cols-2 md:flex ">
          <li class="p-2 mx-[1.5rem] flex items-center uppercase">
            <img class="mr-1" src="@/assets/images/dashboard.png" alt="">
            Dashboard
          </li>
          <li class="p-2 mx-[1.5rem] flex items-center uppercase">
            <img class="mr-1" src="@/assets/images/person.png" alt="">Profile
          </li>
          <li class="p-2 mx-[1.5rem] flex items-center uppercase">
            <img class="mr-1" src="@/assets/images/signup.png" alt="">Signup
          </li>
          <li class="p-2 mx-[1.5rem] flex items-center uppercase">
            <img class="mr-1" src="@/assets/images/key.png" alt="">Signin
          </li>
        </ul>
      </div>
      <div>
        <button class="bg-[#0075FF] w-[15rem] h-[3.5rem] rounded-[1.6rem]">Free Download</button>
      </div>
    </div> -->
    <div class="md:w-[60%] w-full">
      <img class="h-[100vh]" src="@/assets/images/background.jpg" alt="" />
    </div>
    <div class="md:w-[40%] w-0 flex items-center justify-center ">
      <div class="w-[45rem] bg-[#0C1035] border absolute md:static left-1/2 transform -translate-x-1/2 md:left-0 md:transform-none border-input_login p-20 rounded-[3rem]">
        <div class="text-center ">
          <h1 class="font-bold text-white text-[30px]">Welcome!</h1>
          <p class="text-[1.4rem] text-seconday">
            Use these awesome forms to login or create new account in your project for free.
          </p>
        </div>
        <div class="mt-[2rem]">
          <label class="text-white ml-2" for="name">Username</label>
          <div class="gradient-border-wrapper border border-input_login rounded-[2rem]">
            <input type="text" v-model="account_signup.username" placeholder="Username" />
          </div>
        </div>
        <div class="mt-[2rem]">
          <label class="text-white ml-2" for="country">Country</label>
          <div class="gradient-border-wrapper border border-input_login rounded-[2rem] flex justify-start">
            <!-- <select v-model="account_signup.country" class="border text-secondary border-input_login font-medium bg-transparent w-[90%] px-[2rem] py-[1.5rem] rounded-[2rem]">
              <option value="">Your country</option>
              <option value="US">United States</option>
              <option value="CA">Canada</option>
              <option value="FR">France</option>
              <option value="DE">Germany</option>
            </select> -->

            <v-select
                  id="email"
              :options="countries"
              :reduce="c => c._id"
              label="name"
              class="border text-secondary border-input_login font-medium bg-transparent w-[100%] px-[1rem] py-[1rem] rounded-[2rem]"
              v-model="account_signup.country_id"
          ></v-select>
          </div>
        </div>
        <div class="mt-[2rem]">
          <label class="text-white ml-2" for="email">Email</label>
          <div class="gradient-border-wrapper border border-input_login rounded-[2rem]">
            <input type="email" v-model="account_signup.email" placeholder="Your email address" />
          </div>
        </div>
        <div class="mt-[2rem]">
          <label class="text-white ml-2" for="password">Password</label>
          <div class="gradient-border-wrapper border border-input_login rounded-[2rem]">
            <input type="password" v-model="account_signup.password" placeholder="Your password" />
          </div>
        </div>
        <div class="mt-[2rem]">
          <label class="text-white ml-2" for="password">Refer Code</label>
          <div class="gradient-border-wrapper border border-input_login rounded-[2rem]">
            <input type="text" v-model="account_signup.refer_code" placeholder="Refer code" />
          </div>
        </div>
        <div class="mt-[2rem]">
          <label class="inline-flex items-center cursor-pointer">
            <input type="checkbox" v-model="account_signup.remember" class="sr-only peer border border-input_login rounded-[2rem]" />
            <div class="relative w-[36px] h-[18.5px] bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-[134%] rtl:peer-checked:after:-translate-x-[145%] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2.5px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[13.5px] after:w-[13.5px] after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span class="ms-3 text-sm font-medium">Remember me</span>
          </label>
        </div>
        <div class="mt-[2rem]">
          <button @click="handleSubmit" class="w-full uppercase bg-[#0075FF] rounded-[1.6rem] h-[45px] text-[1.5rem]">Sign up</button>
        </div>
        <div class="mt-[2rem]">
          <span class="text-seconday">
            Already have an account? <router-link class="text-white font-bold" to="/login">Sign in</router-link>
          </span>
        </div>
      </div>
    </div>
  </div>
  <Loading v-if="is_loading"/>
</template>

<script>
import network from '@/network/network';
import Loading from '@/components/Loading';

export default {
  name: "LoginForm",
  components: {Loading},
  data() {
    return {
      is_loading: false,
      account_signup: {
        username: "",
        // country: "",
        email: "",
        password: "",
        remember: false,
        country_id: "",
        refer_code: "",
      },
      countries: [],
    };
  },
  mounted() {
    this.getListCountry();
    if (this.$route.params.id) {
      localStorage.setItem('refer_code', this.$route.params.id)
    }

    this.account_signup.refer_code = localStorage.getItem('refer_code');
  },
  methods: {
    saveAccount() {
      if (this.account_signup.remember) {
        localStorage.setItem('account', JSON.stringify(this.account_signup));
      } else {
        localStorage.removeItem('account');
      }
    },
    handleSubmit() {

      this.is_loading = true;
        network.post('user/signup', this.account_signup).then(res => {
          this.$toast.open({
            message: "successs",
            type: "success",
          });
          const data =  res.data.metaData.metaData;
        localStorage.setItem('accessToken', data.token.accessToken);
        localStorage.setItem('refreshToken', data.token.refreshToken);
        localStorage.setItem('_id', data.shop._id);
        localStorage.setItem('email', data.shop.email);
        localStorage.setItem('username', data.shop.username);
        this.saveAccount();
          this.is_loading = false;
          window.location.href = '/';

        }).catch(err => {
          this.is_loading = false;
          this.$toast.open({
            message: err.response.data?.message,
            type: "error",
          
        })
      })
    },
    getListCountry() {
      network.get('countries').then(res => {
        this.countries = res.data.metaData;
        
      }).catch(err => {
        console.log(err);
      });
    },
  }
};
</script>

<style scoped>
input {
  width: 33.1rem;
  height: 5rem !important;
  border-radius: 2rem;
  padding: 2rem;
  background: transparent;
  color: white;
}

.gradient-border-wrapper {
  position: relative;
  width: 33.1rem;
  height: 5rem;
}

.gradient-border-wrapper input {
  width: 100%;
  height: 100%;
  padding: 2rem;
  border: none;
  outline: none;
  border-radius: 2rem;
  background: transparent;
  position: relative;
  z-index: 1;
}

.gradient-border-wrapper select {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  border-radius: 2rem;
  background: transparent;
  position: relative;
  z-index: 1;
}

.gradient-border-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 2rem;
  padding: 2px; /* Width of the gradient border */
  background: radial-gradient(circle, gray, #151515 100%, #582cff 100%, #ffffff 100%);
  -webkit-mask: linear-gradient(gray 0 0) content-box, linear-gradient(gray 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  z-index: 0;
}

::v-deep .vs__dropdown-menu {
    background: #042968;
    border-radius: 5px;
    margin-top: 1rem;
  }
  ::v-deep .v-select, ::v-deep .vs__selected {
    color: #fff;
  }
  ::v-deep .vs--searchable .vs__dropdown-toggle {
    border: none ;
  }
  
  ::v-deep  .vs__dropdown-menu::-webkit-scrollbar {
      width: 5px; /* Chiều rộng của thanh cuộn */
      
  }
  
  ::v-deep .vs__dropdown-menu::-webkit-scrollbar-track {
      background-color: #161e5c; /* Màu nền của thanh cuộn */
  }
  
  ::v-deep .vs__dropdown-menu::-webkit-scrollbar-thumb {
      background-color: #21339b; /* Màu của nút thanh cuộn */
      border-radius: 6px; /* Độ cong của nút thanh cuộn */
  }
  
  ::v-deep .vs__dropdown-menu::-webkit-scrollbar-thumb:hover {
      background-color: rgb(18, 17, 90); /* Màu của nút thanh cuộn khi hover */
  }

  ::v-deep .vs__dropdown-option {
    padding: 10px;
  }
</style>
