<template>
  <div class="bg-gray-800 shadow-md rounded my-6 p-6">
    <h2 class="text-xl font-bold mb-4 text-white">Add Machine</h2>
    <div class="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
      <div>
        <label class="block text-sm text-white mb-1">Avatar</label>
        <input
          @change="onFileChange"
          type="file"
          class="border p-2 bg-gray-700 text-white w-full"
        />
        <img
          v-if="this.machine.icon"
          :src="getImg()"
          alt="Avatar Preview"
          class="mt-2 w-32 h-32 object-cover rounded"
        />
      </div>
      <div>
        <label class="block text-sm text-white mb-1">Name</label>
        <input
          v-model="machine.name"
          type="text"
          placeholder="Name"
          class="border p-2 bg-gray-700 text-white w-full"
        />
      </div>
      <!-- <div>
        <label class="block text-sm text-white mb-1">Icon URL</label>
        <input
          v-model="machine.icon"
          type="text"
          placeholder="Icon URL"
          class="border p-2 bg-gray-700 text-white w-full"
        />
      </div> -->
      <div>
        <label class="block text-sm text-white mb-1">Pool Hashrate</label>
        <input
          v-model="machine.pool_hashrate"
          type="text"
          placeholder="Pool Hashrate"
          class="border p-2 bg-gray-700 text-white w-full"
        />
      </div>
      <div>
        <label class="block text-sm text-white mb-1">Pool Fee</label>
        <input
          v-model="machine.pool_fee"
          type="text"
          placeholder="Pool Fee"
          class="border p-2 bg-gray-700 text-white w-full"
        />
      </div>
      <div>
        <label class="block text-sm text-white mb-1">Workers Online</label>
        <input
          v-model="machine.workers_online"
          type="text"
          placeholder="Workers Online"
          class="border p-2 bg-gray-700 text-white w-full"
        />
      </div>
      <div>
        <label class="block text-sm text-white mb-1">Miners Online</label>
        <input
          v-model="machine.miners_online"
          type="text"
          placeholder="Miners Online"
          class="border p-2 bg-gray-700 text-white w-full"
        />
      </div>
      <div>
        <label class="block text-sm text-white mb-1">Block Rewards</label>
        <input
          v-model="machine.block_rewards"
          type="text"
          placeholder="Block Rewards"
          class="border p-2 bg-gray-700 text-white w-full"
        />
      </div>
      <div>
        <label class="block text-sm text-white mb-1">Network Hashrate</label>
        <input
          v-model="machine.network_hashrate"
          type="text"
          placeholder="Network Hashrate"
          class="border p-2 bg-gray-700 text-white w-full"
        />
      </div>
      <div>
        <label class="block text-sm text-white mb-1">Block</label>
        <input
          v-model="machine.block"
          type="text"
          placeholder="Block"
          class="border p-2 bg-gray-700 text-white w-full"
        />
      </div>
      <div>
        <label class="block text-sm text-white mb-1">Luck</label>
        <input
          v-model="machine.luck"
          type="text"
          placeholder="Luck"
          class="border p-2 bg-gray-700 text-white w-full"
        />
      </div>
      <div>
        <label class="block text-sm text-white mb-1">Rewards</label>
        <input
          v-model="machine.rewards"
          type="text"
          placeholder="Rewards"
          class="border p-2 bg-gray-700 text-white w-full"
        />
      </div>
      <div>
        <label class="block text-sm text-white mb-1">Duration</label>
        <input
          v-model="machine.duration"
          type="text"
          placeholder="Duration"
          class="border p-2 bg-gray-700 text-white w-full"
        />
      </div>
      <div>
        <label class="block text-sm text-white mb-1">Network Difficulty</label>
        <input
          v-model="machine.network_difficulty"
          type="text"
          placeholder="Network Difficulty"
          class="border p-2 bg-gray-700 text-white w-full"
        />
      </div>
      <div>
        <label class="block text-sm text-white mb-1">Type</label>
        <input
          v-model="machine.type"
          type="text"
          placeholder="Type"
          class="border p-2 bg-gray-700 text-white w-full"
        />
      </div>
      <div>
        <label class="block text-sm text-white mb-1">Status</label>
        <input
          v-model="machine.status"
          type="text"
          placeholder="Status"
          class="border p-2 bg-gray-700 text-white w-full"
        />
      </div>
      <div>
        <label class="block text-sm text-white mb-1">Price</label>
        <input
          v-model="machine.price"
          type="text"
          placeholder="Price"
          class="border p-2 bg-gray-700 text-white w-full"
        />
      </div>
      <div>
        <label class="block text-sm text-white mb-1">Probability</label>
        <input
          v-model="machine.probability"
          type="text"
          placeholder="Probability"
          class="border p-2 bg-gray-700 text-white w-full"
        />
      </div>
      <div>
        <label class="block text-sm text-white mb-1"
          >Potential Block Reward</label
        >
        <input
          v-model="machine.potential_block_reward"
          type="text"
          placeholder="Potential Block Reward"
          class="border p-2 bg-gray-700 text-white w-full"
        />
      </div>
      <div class="md:col-span-2">
        <label class="block text-sm text-white mb-1">Description</label>
        <textarea
          v-model="machine.description"
          placeholder="Description"
          class="border p-2 bg-gray-700 text-white w-full"
          rows="4"
        />
      </div>
    </div>
    <button
      @click="addMachine()"
      class="bg-blue-500 text-white p-2 rounded"
    >
      Add Machine
    </button>
  </div>
</template>

<script>
import network from "@/network/network";

export default {
  data() {
    return {
      avatarSrc: null,
      avatarFile: null,
      machine: {
        name: "Litecoin SOLO Mining Solo",
        icon: "https://i.pravatar.cc/300",
        pool_hashrate: "100.01 GH/s",
        pool_fee: "1.5%",
        workers_online: "22",
        miners_online: "44",
        block_rewards: "2 BCH",
        network_hashrate: "21.11 TH/s",
        block: "103963",
        luck: "25",
        rewards: "461.15",
        duration: "2",
        network_difficulty: "507.58 G",
        type: "1",
        status: "1",
        price: "100",
        probability: "1:728",
        potential_block_reward: "0.00707504 BTC",
        description:
          "The most profitable Bitcoin Cash mining pool for GPU and ASIC. Regular payments, tutorials, reliable servers, rig monitoring bot.",
      },
    };
  },
  methods: {
    async addMachine() {
      await network
        .post("package", this.machine)
        .then((res) => {
          this.$toast.open({
            message: res.data.message,
            type: "success",
          });
          this.resetForm();
        })
        .catch((err) => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
          });
        });
    },
    getImg() {
      return network.getImg(this.machine.icon);
    },
    async onFileChange(event) {
      const file = event.target.files[0];

      const formData = new FormData();
      formData.append("image", file);

      try {
        const response = await network.post("/resources/upload", formData);
        this.machine.icon = response.data.metaData.image_path;

      } catch (error) {
        console.error("Error uploading avatar:", error);
        this.$toast.open({
          message: "Error uploading avatar",
          type: "error",
        });
      }
    },
    resetForm() {
      this.machine = {
        name: "",
        icon: "",
        pool_hashrate: "",
        pool_fee: "",
        workers_online: "",
        miners_online: "",
        block_rewards: "",
        network_hashrate: "",
        block: "",
        luck: "",
        rewards: "",
        duration: "",
        network_difficulty: "",
        type: "",
        status: "",
        price: "",
        probability: "",
        potential_block_reward: "",
        description: "",
      };
    },
  },
};
</script>

<style scoped>
.grid-cols-2 > * {
  min-width: 0; /* Fix for overflowing content in flexbox */
}
</style>
