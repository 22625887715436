<template>
  <div>
    <input 
      type="text" 
      v-model="searchQuery" 
      placeholder="Search..." 
      class="mb-4 p-2 border rounded text-black"
    />
    <div class="max-h-[80vh] h-[80vh] overflow-auto">
      <table class=" bg-gray-700 text-white">
        <thead>
          <tr>
            <th v-for="column in columns" :key="column.key" class="py-2 px-4">{{ column.label }}</th>
            <th v-if="actions.length" class="py-2 px-4">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in filteredData" :key="index" class="border-b border-gray-600">
            <td v-for="column in columns" :key="column.key" class="py-2 px-4">
              <span v-if="column.label  == 'Icon'"><img :src="item[column.key]" class="w-[40px] h-[40px]" alt=""></span>
              <span v-else >{{ item[column.key] }} </span> 
            </td>
            <td v-if="actions.length" class="py-2 px-4">
              <button
                v-for="(action, actionIndex) in actions"
                :key="actionIndex"
                @click="action.handler(item)"
                :class="action.class"
                class="p-2 rounded mr-2"
              >
                {{ action.label }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    actions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      searchQuery: ''
    };
  },
  computed: {
    filteredData() {
      if (!this.searchQuery) {
        return this.data;
      }
      return this.data.filter(item => {
        return this.columns.some(column => {
          return String(item[column.key]).toLowerCase().includes(this.searchQuery.toLowerCase());
        });
      });
    }
  }
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
th, td {
  text-align: left;
  padding: 8px;
}
th {
  background-color: #2d3748;
}
</style>
