<template>
    <div class="fixed inset-0 flex items-center justify-center bg-slate-900 bg-opacity-90 z-50">
      <div class="flex items-center justify-center h-screen">
      <div class="relative">
          <div class="h-24 w-24 rounded-full border-t-8 border-b-8 border-gray-200"></div>
          <div class="absolute top-0 left-0 h-24 w-24 rounded-full border-t-8 border-b-8 border-blue-500 animate-spin">
          </div>
      </div>
  </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LoadingSpinner',
  }
  </script>
  
  <style scoped>
  </style>
  