<template>
  <div class="container mx-auto p-4">
    <h1 class="text-2xl font-bold mb-4">Withdraw Management</h1>
    <DynamicTable :data="withdraw" :columns="columns" :actions="tableActions" />
    <Modal v-if="showModal" :show="showModal" @close="showModal = false" @submit="handleApprove" />
  </div>
</template>

<script>
import DynamicTable from '@/components/DynamicTable.vue';
import Modal from '@/components/ModalHash.vue';
import network from '@/network/network';

export default {
  name: 'AdminMachine',
  components: {
    DynamicTable,
    Modal
  },
  data() {
    return {
      withdraw: [],
      currentMachine: null,
      isEditing: false,
      columns: [
        { key: 'username', label: 'User Name' },
        { key: 'amount', label: 'Amount' },
        { key: 'type', label: 'Type' },
        { key: 'status', label: 'Status' },
        { key: 'createdAt', label: 'createdAt' },
      ],
      tableActions: [
        {
          label: 'Approve',
          handler: this.showApproveModal,
          class: 'bg-green-600 text-white'
        },
        {
          label: 'Pending',
          handler: this.Pending,
          class: 'bg-yellow-500 text-white'
        },
        {
          label: 'Reject',
          handler: this.Reject,
          class: 'bg-red-500 text-white'
        },
      ],
      showModal: false,
      currentRequestId: null,
    };
  },
  mounted() {
    this.getWithdraw();
  },
  methods: {
    getWithdraw() {
      network.get('withdraw/request').then(res => {
        this.withdraw = res.data.metaData.map(w => ({ ...w, username: w.user_id.username }));
      }).catch(err => {
        console.log(err);
      });
    },
    showApproveModal(item) {
      this.currentRequestId = item._id;
      this.showModal = true;
    },
    handleApprove(data) {
      network.post('withdraw/request/handle/', {
        request_id: this.currentRequestId,
        status: 1,
        amount: data.amount,
        hash: data.hash,
      }).then(res => {
        this.$toast.open({
          message: res.data.message,
          type: "success"
        });
        this.getWithdraw();
        this.showModal = false;
      }).catch(err => {
        this.$toast.open({
          message: err.response.data.message,
          type: "error"
        });
        this.showModal = false;
      });
    },
    Pending(item) {
      network.post('withdraw/request/handle/', {
        request_id: item._id,
        status: 2
      }).then(res => {
        this.$toast.open({
          message: res.data.message,
          type: "success"
        });
        this.getWithdraw();
      }).catch(err => {
        this.$toast.open({
          message: err.response.data.message,
          type: "error"
        });
      });
    },
    Reject(item) {
      network.post('withdraw/request/handle/', {
        request_id: item._id,
        status: 3
      }).then(res => {
        this.$toast.open({
          message: res.data.message,
          type: "success"
        });
        this.getWithdraw();
      }).catch(err => {
        this.$toast.open({
          message: err.response.data.message,
          type: "error"
        });
      });
    },
    toPath(path) {
      this.$router.push(path);
    },
   
  }
};
</script>
