<template>
<section class="2xl:flex xl:flex lg:flex lg:flex-wrap xl:justify-between lg:justify-between  md:grid md:grid-cols-2">
    <div class="card rounded-[2rem] px-10 py-5 flex justify-between items-center lg:w-[24%] md:w-[98%] xs:w-full">
      <div>
        <span class="text-[1.4rem] text-seconday">Daily rewards</span>
        <div class="text-[10.8remem] font-bold">
          ${{ formatNumber(profile?.total_daily_earned)
          }}<span class="text-[1.4rem] text-[#01B574]">
            +{{ totalPercentReward() }}%
          </span>
        </div>
      </div>
      <div>
        <i class="fa-solid fa-globe rounded-[1.6rem] p-6 bg-[#0075FF] text-[15px]"></i>
      </div>
    </div>
    <div class="card rounded-[2rem] px-10 py-5 flex justify-between items-center lg:w-[24%] md:w-[98%] sm:mt-5 mt-5 lg:mt-0 xs:w-full">
      <div>
        <span class="text-[1.4rem] text-seconday">Solo</span>
        <div class="text-[10.8remem] font-bold">
          ${{ formatNumber(profile.solo?.solo_total_earned) }}
          <!-- <span class="text-[1.4rem] text-[#01B574]"
            >+{{ Number(profile.solo?.solo_percent_change) ? profile.solo?.solo_percent_change : 0 }}%</span
          > -->
        </div>
      </div>
      <div>
        <i class="fa-solid fa-cart-shopping rounded-[1.6rem] p-6 bg-[#0075FF] text-[15px]"></i>
      </div>
    </div>
    <div class="card rounded-[2rem] px-10 py-5 flex justify-between items-center lg:w-[24%]  md:w-[98%] lg:mt-0 md:mt-5 sm:mt-5 mt-5 lg:mt-0 xs:w-full">
      <div>
        <span class="text-[1.4rem] text-seconday">Pool</span>
        <div class="text-[10.8remem] font-bold">
          ${{ formatNumber(this.profile.pool?.pool_total_earned) }}
          <!-- <span class="text-[1.4rem] text-[#01B574]"
            >+{{ Number(profile.solo?.pool_percent_change) ? profile.solo?.pool_percent_change : 0 }}%</span
          > -->
        </div>
      </div>
      <div>
        <i class="fa-solid fa-cart-shopping rounded-[1.6rem] p-6 bg-[#0075FF] text-[15px]"></i>
      </div>
    </div>
    <div class="card rounded-[2rem] px-10 py-5 flex justify-between items-center lg:w-[24%] lg:mt-0 md:w-[98%] md:mt-5 sm:mt-5 mt-5 lg:mt-0 xs:w-full">
      <div>
        <span class="text-[1.4rem] text-seconday">Total</span>
        <div class="text-[10.8remem] font-bold">
          ${{ formatNumber(profile.wallet_amount) }}
        </div>
      </div>
      <div>
        <i class="fa-solid fa-wallet rounded-[1.6rem] p-6 bg-[#0075FF] text-[15px]"></i>
      </div>
    </div>
  </section>
</template>

<script>
// import network from "@/network/network";
export default {
  name: "FluctuaInfo",
  props: {
    profile: {
      type: Object,
    }
  },
  data() {
    return {

    };
  },
  methods: {
    totalPercentReward() {
      const soloPercentChange = this.profile?.solo?.solo_percent_change ?? 0;
      const poolPercentChange = this.profile?.pool?.pool_percent_change ?? 0;
      const totalPercentChange =
        Number(soloPercentChange) + Number(poolPercentChange);
      return totalPercentChange ? totalPercentChange : 0;
    },

    totalDailyEarn() {
      const totalDailyEarn =
        this.profile?.solo?.solo_total_earned +
        this.profile.solo?.pool_total_earned;
      return totalDailyEarn ? this.formatNumber(totalDailyEarn) : 0;
    },
    formatNumber(num) {
      return Number(num)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style></style>