<template>
  <div class="flex h-[100vh] auth bg-[#0C1035]">
    <!-- <div class="tool-bar md:w-[50%] w-[98%] mx-5 rounded-[2rem] border-2 border-input_login border-gradient-to-br items-center justify-between  absolute md:translate-x-[50%] -translate-x-1 flex backdrop-blur-md mt-[2.4rem] h-[10rem] p-[2.4rem]">
        <div>
            <h1>VISION UI FREE</h1>
        </div>
        <div>
            <ul class="grid grid-cols-2 md:flex">
                <li class="p-2 mx-[1.5rem] flex items-center uppercase">
                    <img class="mr-1" src="@/assets/images/dashboard.png" alt="">
                    Dashboard</li>
                <li class="p-2 mx-[1.5rem]  flex items-center uppercase"><img  class="mr-1" src="@/assets/images/person.png" alt="">Profile</li>
                <li class="p-2 mx-[1.5rem]  flex items-center uppercase"><img  class="mr-1" src="@/assets/images/signup.png" alt="">Signup</li>
                <li class="p-2 mx-[1.5rem]  flex items-center uppercase"><img  class="mr-1" src="@/assets/images/key.png" alt="">Signin</li>
            </ul>
        </div>
        <div>
            <button class="bg-[#0075FF] w-[15rem] h-[3.5rem] rounded-[1.6rem]">Free Download</button>
        </div>
    </div> -->
    <div class="md:w-[60%] w-full">
      <img class="h-[100vh] w-[100%]"   src="@/assets/images/background.jpg" alt="" />
    </div>
    <div class="md:w-[40%] w-0 flex items-center justify-center ">
      <div class="md:w-[31.1rem] md:mx-auto absolute md:static bg-[#0C1035] rounded-2xl md:p-0 p-10 w-[80%] left-1/2 transform -translate-x-1/2 md:left-0 md:transform-none">
        <h1 class="font-bold text-white text-[30px]">Nice to see you!</h1>
        <p class="text-[1.6rem] text-seconday">
          Enter your email and password to sign in
        </p>
        <div class="mt-[2.4rem] w-full">
          <label class="text-white ml-2" for="email">Email</label>
          <div class="w-full gradient-border-wrapper border border-input_login rounded-[2rem]">
            <input class="w-full" type="email" v-model="account.email" placeholder="Your email address" />
          </div>
        </div>
        <div class="mt-[2.4rem]">
          <label class="text-white ml-2" for="password">Password</label>
          <div class="gradient-border-wrapper border border-input_login rounded-[2rem]">
            <input id="password" v-model="account.password" type="password" placeholder="Your password" />
          </div>
        </div>
        <div class="mt-[2.4rem]">
          <label class="inline-flex items-center cursor-pointer">
            <input type="checkbox" v-model="account.remember" class="sr-only peer" />
            <div
              class="relative w-[36px] h-[18.5px] bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-[134%] rtl:peer-checked:after:-translate-x-[145%] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2.5px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[13.5px] after:w-[13.5px] after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" after:
            ></div>
            <span
              class="ms-3 font-medium"
              >Remember me</span
            >
          </label>
        </div>
        <div class="mt-[2.4rem]">
            <button @click="handleSubmit" class="w-full uppercase bg-[#0075FF] rounded-[1.6rem] h-[45px] text-[1.5rem]">
                Sign in
            </button>
        </div>
        <div class="mt-[2.4rem]">
            <span class="text-seconday">
                Don't have an account? <router-link class="text-white font-bold" to="signup">Sign up</router-link>
            </span>
        </div>
      </div>
    </div>
  </div>
<Loading  v-if="is_loading"/>
</template>

<script>
import network from '@/network/network';
import Loading from '@/components/Loading';
export default {
  name: "LoginForm",
  components: {Loading},
  data() {
    return {
      is_loading: false,
      account: {
        email: "",
        password: "",
        remember: false
      }
    }
  },
  mounted() {
    const account = JSON.parse(localStorage.getItem('account'));
    if (account) {
      this.account = account;
    }
  },
  methods: {
    saveAccount() {
      if (this.account.remember) {
        localStorage.setItem('account', JSON.stringify(this.account));
      } else {
        localStorage.removeItem('account');
      }
    },
    handleSubmit() {
      this.is_loading = true;
      network.post('user/login', this.account).then(res => {
        const data =  res.data.metaData;
        localStorage.setItem('accessToken', data.tokens.accessToken);
        localStorage.setItem('refreshToken', data.tokens.refreshToken);
        localStorage.setItem('_id', data.user._id);
        localStorage.setItem('email', data.user.email);
        localStorage.setItem('username', data.user.username);
        localStorage.setItem('role', data.user.is_admin ? 'admin' : 'user');
        this.$toast.open({
          message: res.data.message,
          type: "success",
        });
        this.saveAccount();

        window.location.href = '/';
        this.is_loading = false;
      }).catch(err => {
        this.is_loading = false;
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
        });
      })
    }
  }
      // Simulate API call
};
</script>

<style scoped>
input {
  width: 33.1rem;
  height: 5rem !important;
  border-radius: 2rem;
  padding: 2rem;
  background: transparent;
  color: white;
}

.gradient-border-wrapper {
  position: relative;
  /* width: 33.1rem; */
  height: 6rem;
}

.gradient-border-wrapper input {
  width: 100%;
  height: 100%;
  padding: 2rem;
  border: none;
  outline: none;
  border-radius: 2rem;
  background: transparent;
  position: relative;
  z-index: 1;
}

.gradient-border-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 2rem;
  padding: 2px; /* Width of the gradient border */
  background: radial-gradient(
    circle,
    gray,
    #151515 100%,
    #582cff 100%,
    #ffffff 100%
  );
  -webkit-mask: linear-gradient(gray 0 0) content-box, linear-gradient(gray 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  z-index: 0;
}
</style>