<template>
  <div class="container mx-auto p-4">
    <h1 class="text-2xl font-bold mb-4 text-white">Machine Management</h1>
    <!-- <AddMachine @add-machine="addMachine" /> -->
    <div class="mb-5">
      <button class="p-3 bg-primary rounded-md" @click="toPath('/admin/machine/add-machine')">Add Machine</button>
    </div>
    <DynamicTable :data="machines" :columns="columns" :actions="tableActions" />
    <!-- <EditMachine
      v-if="isEditing"
      :machine_id="currentMachine"
      @update-machine="updateMachine"
      @cancel-edit="cancelEdit"
    /> -->
  </div>
</template>

<script>
// import AddMachine from './AddMachine.vue';
// import EditMachine from './EditMachine.vue';
import DynamicTable from '@/components/DynamicTable.vue';
import network from '@/network/network';
export default {
  name: 'AdminMachine',
  components: {
    // AddMachine,
    // EditMachine,
    DynamicTable
  },
  data() {
    return {
      machines: [
        {
          "_id": "66710b917d175025feba29ac",
          "name": "Clore.ai Mining Pool",
          "icon": "https://i.pravatar.cc/300",
          "pool_hashrate": "100.01 GH/s",
          "pool_fee": "1.5%",
          "workers_online": "22",
          "miners_online": "44",
          "block_rewards": "2 BCH",
          "network_hashrate": "21.11 TH/s",
          "block": 103963,
          "luck": 25,
          "rewards": 461.15,
          "duration": 2,
          "network_difficulty": "507.58 G",
          "type": 1,
          "status": 1,
          "price": 6.51,
          "probability": "1:728",
          "potential_block_reward": "0.00707504 BTC",
          "description": "The most profitable Bitcoin Cash mining pool for GPU and ASIC. Regular payments, tutorials, reliable servers, rig monitoring bot.",
          "createdAt": "2024-06-18T04:22:41.865Z",
          "updatedAt": "2024-06-18T04:22:41.865Z",
          "__v": 0
        }
      ],
      currentMachine: null,
      isEditing: false,
      columns: [
        { key: 'icon', label: 'Icon' },
        { key: 'name', label: 'Name' },
        // { key: 'icon', label: 'Icon', isImage: true },
        { key: 'pool_hashrate', label: 'Pool Hashrate' },
        { key: 'pool_fee', label: 'Pool Fee' },
        { key: 'workers_online', label: 'Workers Online' },
        { key: 'miners_online', label: 'Miners Online' },
        { key: 'block_rewards', label: 'Block Rewards' },
        { key: 'network_hashrate', label: 'Network Hashrate' },
        { key: 'block', label: 'Block' },
        { key: 'luck', label: 'Luck' },
        { key: 'rewards', label: 'Rewards' },
        { key: 'duration', label: 'Duration' },
        // { key: 'network_difficulty', label: 'Network Difficulty' },
        // { key: 'type', label: 'Type' },
        // { key: 'status', label: 'Status' },
        // { key: 'price', label: 'Price' },
        // { key: 'probability', label: 'Probability' },
        // { key: 'potential_block_reward', label: 'Potential Block Reward' },
        // { key: 'description', label: 'Description' }
      ],
      tableActions: [
        {
          label: 'Edit',
          handler: this.editMachine,
          class: 'bg-yellow-500 text-white'
        },
        {
          label: 'Delete',
          handler: this.deleteMachine,
          class: 'bg-red-500 text-white'
        }
      ]
    };
  },
  mounted() {
    this.getPackage();
  },
  methods: {
    getPackage() {
      network.get('package').then(res => {
        this.machines = res.data.metaData.map(p => ({...p, icon: network.getImg(p.icon) }));

      }).catch(err => {
        console.log(err);
      });
    },
 
    toPath(path) {
      this.$router.push(path)
    },
    
    editMachine(item) {
      this.$router.push(`/admin/machine/edit-machine/${item._id}`);
    },
    deleteMachine(item) {
  // Show a confirmation dialog
  if (window.confirm("Are you sure you want to delete this package?")) {
    network.delete('package/' + item._id).then(res => {
      this.$toast.open({
        message: res.data.message,
        type: "successs", // Fixed typo: changed "success" to "successs"
      });
      this.getPackage();
    }).catch(err => {
      this.$toast.open({
        message: err.response.data.message,
        type: "error",
      });
    });
  }
},

    updateMachine(updatedMachine) {
      const index = this.machines.findIndex(machine => machine._id === updatedMachine._id);
      if (index !== -1) {
        this.machines.splice(index, 1, updatedMachine);
      }
      this.isEditing = false;
      this.currentMachine = null;
    },
    cancelEdit() {
      this.isEditing = false;
      this.currentMachine = null;
    },
    
  }
};
</script>
