<template>
  <div class="md:absolute top-5 md:-translate-x-[50%] left-[50%] flex w-[100px] py-5 md:py-0 mx-auto">
      <button @click="changeType(2)" class="text-[3rem] font-bold uppercase mr-5" :class="{'text-secondary' : type == 1}">Pool </button>
      <span class="text-[3rem]">/</span>
      <button @click="changeType(1)" class="text-[3rem] font-bold uppercase ml-5" :class="{'text-secondary' : type == 2}">Solo</button>
  </div>
  <div class="pr-5">

    <section class=" flex items-center flex-wrap justify-between">
      <div  class="border-2 border-transparent  hover:cursor-pointer hover:border-primary lg:w-[19%] w-[49%] mt-5 flex items-center bg-coin rounded-full " 
      v-for="(c) in cryptoList" :key="c"
      >
        <div>
          <img class="w-[6rem] p-2 mr-4 rounded-[50%]" :src="c.icon" alt="">
        </div>
        <div>
          <div class="text-[1.4rem] text-secondary">{{c.symbol}}</div>
          <div class="text-[2.4rem]  mr-2 font-bold flex items-end">{{ parseFloat(c.lastPrice).toString() }} <span class="text-[1.4rem] pb-2 pl-2" :class="c.priceChangePercent < 0 ? 'text-red-500' : 'text-green-500'">{{ c.priceChangePercent }}%</span></div>
        </div>
      </div>
    </section>

    <section class="card w-full px-10 py-5 mt-10  rounded-[2rem] relative">
      <div class="flex flex-wrap overflow-y-auto scroll 2xl:h-[77vh] md:h-[76.8vh] lg:h-[78.8vh] h-[50vh]">
        <CardCoin class="lg:w-[33%] md:w-[49.5%] sm:w-[100%] w-[100%] mt-5" 
          v-for="p in packages" 
          :key="p" 
          :is_buy="true" 
          :pkg="p"
          @detail="showDetail"
          @buy="buyPackage"
          />

      </div>


     <DetailCoin v-if="show_coin" :detail="detail" @close="show_coin = null" @buy="buyPackage" />
    </section>
<Loading v-if="isLoading" />

  </div>
</template>

<script>
import CardCoin from "@/components/CardCoin.vue";
import network from "@/network/network";
import DetailCoin from "@/components/DetailCoin.vue";
import Loading from "@/components/Loading.vue";
export default {
  components: { CardCoin, DetailCoin, Loading },
  data() {
    return {
      isLoading: false,
      show_coin: false,
      cryptoList: [],
      coins: [1, 2, 3, 4, 5],
      listCoints: [1, 2, 3, 4, 5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      packages: [],
      listPackagesData: [],
      detail: {},
      type: 2,
    };
  },
  name: "HomePage",
  mounted() {
    this.getPackage();
    this.getListCoin();
  },
  methods: {
    getPackage() {
      this.isLoading = true;
      network.get('package').then(res => {
        this.packages = res.data.metaData;
        this.listPackagesData = res.data.metaData;
        this.isLoading = false;
        this.changeType(2)
        this.show_coin = false;
      }).catch(err => {
        console.log(err);
        this.isLoading = false;
      });
    },
    showDetail(d) {
      this.detail = d;
      this.show_coin = true;
    },
    getListCoin() {
      network.get('crypto').then(res => {
        this.cryptoList = res.data.metaData;
      }).catch(err => {
        console.log(err);
      })
    },
    buyPackage(data) {
        this.isLoading = true;
        network.post('package/buy', {
              "package_id": data.id,
              "auto_renew": data.automatically_renewed
        }).then(res => {
          this.$toast.open({
          message: res.data.message,
          type: "success",
        });

        this.isLoading = false;

        }).catch(err => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
          });
        this.isLoading = false;

        })
      } ,
      changeType(type) {
        this.type = type;
        this.packages = this.listPackagesData.filter(p => p.type == this.type);
      }
  }
};
</script>

<style scoped>
.param .el-param {
  border-bottom: 1px solid rgb(6 43 65);
  border-right: 1px solid rgb(6 43 65);
}
.param .el2-param {
  border-bottom: 1px solid rgb(6 43 65);
}
.param .el3-param {
  border-right: 1px solid rgb(6 43 65);
}

</style>