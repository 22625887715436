import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import '@/assets/main.css'; // Import global CSS file
import ToastPlugin from 'vue-toast-notification';
// Import one of the available themes
// import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueSplide from '@splidejs/vue-splide';

const app = createApp(App);

app.use(VueSplide);
app.component("v-select", vSelect);
app.use(router);
app.use(ToastPlugin, {
    // One of the options
    position: 'top',
    duration: 3000,
});
app.mount('#app');
