import { createRouter, createWebHistory } from 'vue-router';

import Login from "@/views/Auth/Login";
import Signup from "@/views/Auth/Signup";
import Index from "@/views/Layout";
import Machine from "@/views/Layout/Machine";
import Rank from "@/views/Layout/Rank";
import Wallet from '@/views/Layout/Wallet.vue';
import Profile from '@/views/Layout/Profile.vue';
import AccountOther from '@/views/Layout/AccountOther.vue';

// admin
import Admin from '@/views/Admin/index.vue';
import User from '@/views/Admin/User/User.vue';
import UserOverview from '@/views/Admin/User/Overview.vue';
import AdminWallet from '@/views/Admin/Wallet.vue';
import AdminMachine from '@/views/Admin/Machine/Machine.vue';
import AddMachine from '@/views/Admin/Machine/AddMachine.vue';
import EditMachine from '@/views/Admin/Machine/EditMachine.vue';

//deposit
import Deposit from '@/views/Admin/Deposit/Deposit.vue';

// admin withdraw
import AdminWithdraw from '@/views/Admin/Withdraw/index.vue';
import EditWithdraw from '@/views/Admin/Withdraw/Edit.vue';

//Remaining
import PoolRemaining from '@/views/Admin/Remaining/Pool.vue';
import SoloRemaining from '@/views/Admin/Remaining/Solo.vue';

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/signup',
        name: 'Signup',
        component: Signup
    },
    {
        path: '/r/:id',
        name: 'SignupWithReferral',
        component: Signup,
    },
    {
        path: '/admin',
        name: 'Admin',
        component: Admin,
        children: [
            {
                path: 'users',
                name: 'User',
                component: User,
            },
            {
                path: 'overview/:id',
                name: 'Overview',
                component: UserOverview,
            },
            {
                path: 'wallet',
                name: 'Wallet',
                component: AdminWallet,
            },
            {
                path: 'machine',
                name: 'Machine',
                component: AdminMachine,
            },
            {
                path: 'machine/add-machine',
                name: 'AddMachine',
                component: AddMachine,
            },
            {
                path: 'machine/edit-machine/:id',
                name: 'EditMachine',
                component: EditMachine,
            },
            {
                path: 'withdraw',
                name: 'Withdraw',
                component: AdminWithdraw,
            },
           
            {
                path: 'withdraw/edit/:id',
                name: 'EditWithdraw',
                component: EditWithdraw,
            },
            {
                path: 'deposit',
                name: 'Deposit',
                component: Deposit,
            },
            {
                path:'remaining/pool',
                name: 'PoolRemaining',
                component: PoolRemaining,
            },
            {
                path:'remaining/solo',
                name: 'SoloRemaining',
                component: SoloRemaining,
            }
            
        ]
    },
    {
        path: '/',
        name: 'Index',
        component: Index,
        children: [
            {
                path: '',
                name: 'Home',
                component: () => import('@/views/Layout/Home.vue')
            },
            {
                path: 'overview/:id',
                name: 'AccountOther',
                component: AccountOther,
                props: true, // this allows passing params to the component
            },
            {
                path: 'machines',
                name: 'Machines',
                component: Machine,
            },
            {
                path: 'rank',
                name: 'Rank',
                component: Rank,
            },
            {
                path: 'wallet',
                name: 'Wallet',
                component: Wallet,
            },
            {
                path: 'profile',
                name: 'Profile',
                component: Profile,
            },
        ]
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('accessToken'); // replace with your token retrieval method
    const userRole = localStorage.getItem('role'); // replace with your role retrieval method
    if ((to.name === 'Login' || to.name === 'Signup' ) && token) {
        next({ name: 'Home' });
    } else if (to.path.startsWith('/admin') && userRole != 'admin') {
        console.log(3);
        next({ name: 'Home' });
    } else {
        next();
    }
});

export default router;
