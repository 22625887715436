<template>
  <div  class="w-[100%] absolute backdrop-blur rounded-[2.4rem] inset-0 bg-opacity-20 flex items-center justify-center  overlay bg-blue-950 z-20 h-[full]"></div>
      <div  class="absolute inset-0 flex items-center justify-center z-20 -top-96 md:top-0">
        <div class="box md:w-[60%] w-full detail-popup  rounded-[1.6rem]  border-4 border-coin p-5">
          <div class="modal w-full bg-primary rounded-[1.6rem] px-5 py-2 ">
            <div class="flex justify-between rounded-[1.6rem] items-center">
              <div class="flex items-center ">
                <div>
                  <img class="w-[8rem] p-2 mr-4 rounded-[50%]" :src="loadImages(detail?.icon)" alt="">
                </div>
                <div>
                  <h1 class="text-[2.8rem] font-bold">{{ detail?.name }}</h1>
                  <div class="text-[1.6rem] text-secondary">ID:<span> {{ detail?._id }}</span></div>
                  <div class="text-[1.6rem] text-secondary">TELEGRAM BOT:<span> @miners_support</span></div>
                </div>
              </div>
              <div class="text-end ">
                <div class="text-[3.8rem] font-bold">{{ detail?.price }} <span class="text-secondary align-text-top">USDT</span></div>
                <div class="flex" v-if="detail.type == 1">
                  Auto renew
                  <label class="inline-flex items-center cursor-pointer ml-5">
                    <input type="checkbox" class="sr-only peer" />
                    <div
                      class="relative w-[36px] h-[18.5px] bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-[134%] rtl:peer-checked:after:-translate-x-[145%] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2.5px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[13.5px] after:w-[13.5px] after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" after:
                    ></div>
                  </label>
                </div>
              </div>
            </div>

          </div>
          <div class="lg:overflow-y-hidden overflow-y-auto scroll mt-5 "  style="max-height: calc(100vh - 55rem);">
              <!-- pool -->
            <div v-if="detail.type == 2">
              <div class="p-5">
                <div class="flex justify-between items-center pb-5" style="border-bottom: 1px solid rgb(6 43 65);">
                  <div class="w-[70%]">
                    <h1 class="text-[1.8rem] font-medium">Daily rewards</h1>
                    <p class="text-[1.6rem] text-secondary">This is the profit you will receive every day. (Related expenses have been deducted)</p>
                  </div>
                  <div class="text-[2.8rem] flex">
                    ~ {{ detail?.rewards }} 
                    <div class="align-text-top text-right ml-2">
                      <span class=" text-secondary text-[1.3rem] block">USDT </span>
                      <span class=" text-secondary text-[1.3rem] mt-2">Day</span>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="p-5">
                <div class="flex justify-between items-center pb-5" style="border-bottom: 1px solid rgb(6 43 65);">
                  <div class="w-[70%]">
                    <h1 class="text-[1.8rem] font-medium">Duration</h1>
                    <p class="text-[1.6rem] text-secondary">Every package buys you a fixed amount of hashpower and can last between 1 hour up to 4 hours depending on your package</p>
                  </div>
                  <div class="text-[2.8rem]">
                    ~ {{ detail?.duration }} <span class="align-text-top text-secondary text-[1.3rem]">hour</span>
                  </div>
                </div>
              </div>
  
              <div class="p-5">
                <div class="flex justify-between items-center pb-5" style="border-bottom: 1px solid rgb(6 43 65);">
                  <div class="w-[70%]">
                    <h1 class="text-[1.8rem] font-medium">Block rewards</h1>
                    <p class="text-[1.6rem] text-secondary">Probability is calculated for at least one block reward. Read more about probability here.</p>
                  </div>
                  <div class="text-[2rem]">
                    {{ detail?.block_rewards }}
                  </div>
                </div>
              </div>
            </div>

              <!-- // solo  -->
            <div v-if="detail.type == 1">
              <div class="p-5">
                <div class="flex justify-between items-center pb-5" style="border-bottom: 1px solid rgb(6 43 65);">
                  <div class="w-[70%]">
                    <h1 class="text-[1.8rem] font-medium">Duration</h1>
                    <p class="text-[1.6rem] text-secondary">Every package buys you a fixed amount of hashpower and can last between 1 hour up to 4 hours depending on your package</p>
                  </div>
                  <div class="text-[2.8rem]">
                    ~ {{ detail?.duration }} <span class="align-text-top text-secondary text-[1.3rem]">hour</span>
                  </div>
                </div>
              </div>
  
              <div class="p-5 ">
                <div class="flex justify-between items-center pb-5" style="border-bottom: 1px solid rgb(6 43 65);">
                  <div class="w-[70%]">
                    <h1 class="text-[1.8rem] font-medium">Potential Block Reward</h1>
                    <p class="text-[1.6rem] text-secondary">This is the reward you will get for one signed block, paid to your Wallet. If you find more, the reward can be even higher. You can also have the reward paid out to an external wallet instead by checking the box below.</p>
                  </div>
                  <div class="text-[2rem]">
                    ~ {{ detail?.potential_block_reward }}<span class="align-text-top text-secondary text-[1.3rem]"></span>
                  </div>
                </div>
              </div>
  
              <div class="p-5">
                <div class="flex justify-between items-center pb-5" style="border-bottom: 1px solid rgb(6 43 65);">
                  <div class="w-[70%]">
                    <h1 class="text-[1.8rem] font-medium">Probability</h1>
                    <p class="text-[1.6rem] text-secondary">Probability is calculated for at least one block reward. Read more about probability here.</p>
                  </div>
                  <div class="text-[2rem]">
                    {{ detail?.probability }}
                  </div>
                </div>
              </div>
            </div>




            <div class="param w-[98%] flex justify-end items-center px-3 bg-coin rounded-[2rem]">
              <div class="w-[40%]">
                <div class="flex   p-3 el-param justify-center">
                  <div  class="text-[2rem] font-bold mr-4">
                      {{ detail?.pool_hashrate }}
                    </div>
                    <div>
                      <div>
                        Pool
                      </div>
                      <div class="text-secondary">
                        Hashrate
                      </div>
                    </div>
                </div>
                <div class="flex p-3 el3-param justify-center">
                  <div class="text-[2rem] font-bold mr-4">
                    {{ detail?.network_hashrate }}
                  </div>
                  <div>
                    <div class="text-red-600">
                      Network
                    </div>
                    <div class="text-red-900">
                      Hashrate
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-[40%]">
                <div class="flex   p-3 el-param justify-center">
                  <div  class="text-[2rem] font-bold mr-4">
                      {{ detail?.miners_online }}
                    </div>
                    <div>
                      <div>
                        Minners
                      </div>
                      <div class="text-secondary">
                        Online
                      </div>
                    </div>
                </div>
                <div class="flex  p-3 el3-param justify-center">
                  <div class="text-[2rem] font-bold  mr-4">
                    {{ detail?.network_difficulty }}
                  </div>
                  <div>
                    <div class="text-red-600">
                      Network
                    </div>
                    <div class="text-red-900">
                      Difficuly
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-[40%]">
                <div class="flex   p-3 el2-param justify-center">
                  <div  class="text-[2rem] font-bold mr-4">
                      {{ detail?.workers_online }}
                    </div>
                    <div>
                      <div>
                        Workers
                      </div>
                      <div class="text-secondary">
                        Online
                      </div>
                    </div>
                </div>
                <div class="flex  p-3 justify-center">
                  <div class="text-[2rem] font-bold mr-4">
                    {{detail?.luck}}%
                  </div>
                  <div>
                    <div>
                      Luck
                    </div>
                    <div class="text-secondary">
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-5">
              <div class="flex justify-between items-center pb-5" style="border-bottom: 1px solid rgb(6 43 65);">
                <div class="w-[70%]">
                  <h1 class="text-[1.8rem] font-medium">Descriptions</h1>
                  <p class="text-[1.6rem] text-secondary">{{ detail?.description }}</p>
                </div>
                <div class="text-[2.8rem]">
                  {{ detail?.probability }}
                </div>
              </div>
            </div>


          </div>
          <div class="flex p-5 justify-between">
            <button @click="close" class=" w-[48%] border border-primary rounded-[10px] p-3 text-[2rem]">Close</button>
            <button @click="isShowPopup = true" class="w-[48%] border border-primary bg-primary rounded-[10px] p-3 text-[2rem]">Buy</button>
          </div>
        </div>
      </div>
    <PopupConfirm v-if="isShowPopup" @close="isShowPopup = false" @confirm="buy" title="Buy Package" text="Are you sure you want to buy?" type="buy" />

</template>

<script>
import PopupConfirm from '@/components/PopupConfirm.vue'
import network from '@/network/network';

export default {
    name: 'DetailCoin',
    components: {PopupConfirm},
    props: {
      detail: {
        type: Object,
        required: true
      },
      
    },
    data() {
      return {
        automatically_renewed: false,
        isShowPopup: false
      }
    },
    methods: {
      close() {
        this.$emit('close')
      },
      buy() {
        this.$emit('buy', { 
          automatically_renewed: this.automatically_renewed,
          id: this.detail._id, 
        });
        this.isShowPopup = false;
      },
      loadImages(src) {
      return network.getImg(src);
    },
    }
}
</script>

<style>

</style>