<template>
  <div class="flex justify-between px-5 py-5">
    <span class="text-[1.8rem] font-bold">Daily Reward History</span>
    <span class="text-secondary flex items-center">
      <!-- <i class="mr-2 fa-solid fa-calendar-days"></i> -->
      <!-- 23 - 30 March 2020 -->
    </span>
  </div>

  <div
    class="scroll overflow-y-auto mb-5 pl-5 pr-5 relative"
    :class="overview ? 'h-[22rem] 2xl:h-[18rem]' : 'sm:h-[15rem] h-[20vh]'"
  >
    <div
      v-show="historyIncome.length > 0"
      class="flex items-center justify-between mt-3"
      v-for="i in historyIncome"
      :key="i"
    >
      <div class="flex items-center">
        <div class="mr-5">
          <i
            class="px-[1rem] py-[0.8rem] rounded-[50%] text-md border fa-solid"
            :class="{
              'text-blue-500 border-blue-500 fa-arrow-down':
                i.type_id?.type === 1 || i.type_id?.type === 2,
              'text-cyan-400 border-cyan-500 fa-arrow-down':
                i.type_id?.type === 3 || i.type_id?.type === 4,
            }"
          ></i>
        </div>
        <div>
          <div>
            {{ i.type_id?.name + getPackageId(i) }}
          </div>
          <div class="text-secondary">
            {{ formattedDate(`${i.createdAt}`) }}
          </div>
        </div>
      </div>
      <div
        :class="{
          'text-blue-500': i.type_id?.type === 1 || i.type_id?.type === 2,
          'text-cyan-500': i.type_id?.type === 3 || i.type_id?.type === 4,
        }"
      >
        +${{ i.amount }}
      </div>
    </div>

    <div v-show="historyIncome.length == 0" class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">No data</div>
  </div>
</template>

<script>
import network from "@/network/network";
import { format } from "date-fns";
export default {
  name: "historyRewards",

  props: {
    overview: {
      default: false,
    },
    idOverview: {
      tyoe: String,
      default: "",
    },
  },

  data() {
    return {
      historyIncome: [1, 2, 3, 4, 5, 6, 7],
    };
  },
  mounted() {
    this.getRewards();
  },
  methods: {
    getRewards() {
      console.log(this.idOverview, 44);
      network
        .get("rewards" + (this.idOverview ? `/${this.idOverview}` : ""))
        .then((res) => {
          this.historyIncome = res.data.metaData;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPackageId(i) {
      return i.remaining_packages_id ? " - " + i.remaining_packages_id._id : "";
    },
    formattedDate(timestamp) {
      const date = new Date(timestamp);
      if (isNaN(date)) {
        return "Invalid date";
      }
      return format(date, "dd MMMM yyyy, 'at' hh:mm a");
    },
  },
};
</script>

