<template>
    <div class="container mx-auto p-4">
      <h1 class="text-2xl font-bold mb-4">Pool remaining </h1>
      <DynamicTable :data="remaining" :columns="columns" :actions="tableActions" />
      <Modal v-if="showModal" :show="showModal" @close="showModal = false" @submit="handleApprove" />
    </div>
  </template>
  
  <script>
  import DynamicTable from '@/components/DynamicTable.vue';
  import Modal from '@/components/ModalHash.vue';
  import network from '@/network/network';
  
  export default {
    name: 'PoolRemaining',
    components: {
      DynamicTable,
      Modal
    },
    data() {
      return {
        remaining: [],
        currentMachine: null,
        isEditing: false,
        item: {},
        columns: [
        { key: 'username', label: 'User Name' },
          { key: 'rewards', label: 'Rewards' },
          { key: 'duration', label: 'Duration' },
          {key: 'total_earned', label: 'Total Earned'},
          {key: 'origin_price', label: 'Origin Price'},
          { key: 'expired_time', label: 'Expired_time' },
          { key: 'createdAt', label: 'createdAt' },
        ],
        tableActions: [
        //   {
        //     label: 'Details',
        //     handler: this.showApproveModal,
        //     class: 'bg-green-600 text-white'
        //   },
        ],
        showModal: false,
        currentRequestId: null,
      };
    },
    mounted() {
      this.getRemaining();
    },
    methods: {
      getRemaining() {
        network.get('package/pool/remaining').then(res => {
          this.remaining = res.data.metaData.map(w => ({ 
            ...w,
            username: w.user_id.username ,
            rewards: w.package_id.rewards,
            duration: w.package_id.duration,
        }));
        }).catch(err => {
          console.log(err);
        });
      },
      showApproveModal(item) {
        this.currentRequestId = item._id;
        this.item = item;
        this.showModal = true;
      },
      handleApprove(data) {
        network.post('remaining/request/handle/', {
          request_id: this.currentRequestId,
          amount: data.amount,
          hash: data.hash
        }).then(res => {
          this.$toast.open({
            message: res.data.message,
            type: "success"
          });
          this.getRemaining();
          this.showModal = false;
        }).catch(err => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error"
          });
          this.showModal = false;
        });
      },
      
      toPath(path) {
        this.$router.push(path);
      },
    }
  };
  </script>
  