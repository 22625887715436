<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>

#app {
  font-family:ui-sans-serif,system-ui,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  -moz-osx-font-smoothing: grayscale;
}
body {
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
 html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-feature-settings: normal;
    font-variation-settings: normal;
    tab-size: 4;
    font-size: 90.9%;
    @media (max-width: 500px) {
        font-size: 100%;
    }
}
.v-toast__item--success {
  background: linear-gradient(300deg, rgb(31 18 92) 48%, rgb(57 42 126) 65%, rgb(4 10 30) 100%);
}
</style>
