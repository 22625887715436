<template>
    <div v-if="show" class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
      <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
  
      <div class="modal-container bg-white w-[400px] mx-auto rounded shadow-lg z-50 overflow-y-auto text-black">
        <div class="modal-content py-4 text-left px-6">
          <div class="flex justify-between items-center pb-3">
            <p class="text-2xl font-bold text-black">Approve Request</p>
            <div class="modal-close cursor-pointer z-50 text-black" @click="closeModal">
              <svg class="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path d="M14.53 3.53l-1.06-1.06L9 6.94 4.53 2.47 3.47 3.53 8.94 9l-5.47 5.47 1.06 1.06L9 11.06l4.47 4.47 1.06-1.06L9.06 9z"/>
              </svg>
            </div>
          </div>
  
          <!-- <p class="text-black">Enter a message for approval:</p> -->
          <input v-model="number" type="number" class="input input-bordered text-black  my-2 p-3 w-[300px]" placeholder="rewards" />
  
          <div class="flex justify-end pt-2">
            <button class="px-4 bg-gray-200 p-3 rounded-lg text-black mr-2" @click="closeModal">Cancel</button>
            <button class="px-4 bg-blue-500 p-3 rounded-lg text-white" @click="submit">Add</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      show: Boolean
    },
    data() {
      return {
        number: "",
        inputAmount: ""
      };
    },
    methods: {
      closeModal() {
        this.$emit("close");
      },
      submit() {
        this.$emit("submit", this.number);
      }
    }
  };
  </script>
  
  <style scoped>
  .modal {
    z-index: 50;
  }
  .modal-overlay {
    z-index: 40;
  }
  </style>
  