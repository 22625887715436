import axios from 'axios';

const SERVER_URL = 'https://api.cloudminers.io';

const token = localStorage.getItem('accessToken');
const userId = localStorage.getItem('_id');

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: SERVER_URL + '/api/v1/',
  headers: {
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
    'Access-Control-Allow-Origin': '*',
    Authorization: `${token}`,
    'x-client-id': userId,
  },
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Logout function
      logout();
    }
    return Promise.reject(error);
  }
);

// Logout function
function logout() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('_id');
//   this.$toast.open({
// 	message: 'Login session expired!',
// 	type: "error",
// 	});
  window.location.href = '/login'; // Redirect to login page
}

export default {
  get(url, params) {
    return axiosInstance.get(url, { params });
  },

  post(url, data) {
    return axiosInstance.post(url, data);
  },

  put(url, data) {
    // const config = {
    //   headers: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // };
    return axiosInstance.put(url, data);
  },

  delete(url) {
    return axiosInstance.delete(url);
  },

  postNoneAuth(url, data) {
    return axios.post(url, data);
  },

  URI(url) {
    return SERVER_URL + url;
  },

  getImg(nameImg) {
    return SERVER_URL + '/assets/images/' + nameImg;
  }
};
