<template>
  <div class="container mx-auto p-4">
    <h1 class="text-2xl font-bold mb-4">Deposit </h1>
    <DynamicTable :data="deposit" :columns="columns" :actions="tableActions" />
    <Modal v-if="showModal" :show="showModal" @close="showModal = false" @submit="handleApprove" />
  </div>
</template>

<script>
import DynamicTable from '@/components/DynamicTable.vue';
import Modal from '@/components/ModalHash.vue';
import network from '@/network/network';

export default {
  name: 'AdminMachine',
  components: {
    DynamicTable,
    Modal
  },
  data() {
    return {
      deposit: [],
      currentMachine: null,
      isEditing: false,
      item: {},
      columns: [
        { key: 'username', label: 'User Name' },
        { key: 'amount', label: 'Amount' },
        { key: 'type', label: 'Type' },
        { key: 'status', label: 'Status' },
        { key: 'createdAt', label: 'createdAt' },
      ],
      tableActions: [
        {
          label: 'Approve',
          handler: this.showApproveModal,
          class: 'bg-green-600 text-white'
        },
        // {
        //   label: 'Pending',
        //   handler: this.Pending,
        //   class: 'bg-yellow-500 text-white'
        // },
        // {
        //   label: 'Reject',
        //   handler: this.Reject,
        //   class: 'bg-red-500 text-white'
        // },
      ],
      showModal: false,
      currentRequestId: null,
    };
  },
  mounted() {
    this.getDeposit();
  },
  methods: {
    getDeposit() {
      network.get('deposit/request').then(res => {
        this.deposit = res.data.metaData.map(w => ({ ...w, username: w.user_id.username }));
      }).catch(err => {
        console.log(err);
      });
    },
    showApproveModal(item) {
      this.currentRequestId = item._id;
      this.item = item;
      this.showModal = true;
    },
    handleApprove(data) {
      const amount = parseFloat(data.amount);
      network.post('deposit/request/handle/', {
        request_id: this.currentRequestId,
        amount: amount,
        hash: data.hash
      }).then(res => {
        this.$toast.open({
          message: res.data.message,
          type: "success"
        });
        this.getDeposit();
        this.showModal = false;
      }).catch(err => {
        this.$toast.open({
          message: err.response.data.message,
          type: "error"
        });
        this.showModal = false;
      });
    },
    // Pending(id) {
    //   network.post('deposit/request/handle/', {
    //     request_id: id,
    //     status: 1
    //   }).then(res => {
    //     this.$toast.open({
    //       message: res.data.message,
    //       type: "success"
    //     });
    //     this.getDeposit();
    //   }).catch(err => {
    //     this.$toast.open({
    //       message: err.response.data.message,
    //       type: "error"
    //     });
    //   });
    // },
    // Reject(id) {
    //   network.post('deposit/request/handle/', {
    //     request_id: id,
    //     status: 1
    //   }).then(res => {
    //     this.$toast.open({
    //       message: res.data.message,
    //       type: "success"
    //     });
    //     this.getDeposit();
    //   }).catch(err => {
    //     this.$toast.open({
    //       message: err.response.data.message,
    //       type: "error"
    //     });
    //   });
    // },
    toPath(path) {
      this.$router.push(path);
    },
  }
};
</script>
