<template>
    <div class="fixed inset-0 flex items-center justify-center bg-slate-900 bg-opacity-90 z-50">
      <div class="flex items-center justify-center h-screen">
      <div class="relative">
            <div class="lg:w-[500px] w-[300px] h-[auto] border border-primary text-center p-10 rounded-[1.5rem] box">
                <h1 class="text-[3rem] font-semibold">{{ title }}</h1>
                <div class="text-[2.4rem] text-secondary mt-8 mb-10">{{ text }}</div>
                <div class="flex justify-between mt-[5rem]">
                  <button class="w-[48%] py-3 border border-primary rounded-[1rem] text-[2rem]" @click="close">Close</button>
                  <button class="w-[48%] py-4  rounded-[1rem] text-[2rem]" :class="type == 'sell' ? 'bg-red-500' : 'bg-primary'" @click="confirm">Confirm</button>
                </div>
            </div>
      </div>
  </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PopupConfirm',
    props: {
        title: {
          type: String,
          default: 'Are you sure?'
        },
        type: String,
        text: String,
    },
    methods: {
      close() {
        this.$emit('close');
      },
      confirm() {
        this.$emit('confirm');
      }
    }
  }
  </script>
  
  <style scoped>
  </style>
  