<template>
  <div class="card p-4 rounded-[2rem]  mb-5 max-h-[32rem]">
    <div class="flex card-m justify-between p-2 rounded-[1rem] items items-center">
      <div class="flex px-0 w-full items-center">
        <div class="w-12% mr-5">
          <img class="w-[60px] px-1 mr-2 rounded-[50%]" :src="loadImages(pkg?.icon)" alt="" />
        </div>
        <div class="w-[88%]">
          <div class="lg:text-[1.8rem] md:text-[1rem] whitespace-nowrap">
            {{ pkg?.name }}
          </div>
          <div class="flex justify-between items-center">
            <div class="text-secondary">
              Miners: <span>{{ pkg?.miners_online }}</span>
            </div>
            <div class="font-bold text-[2.7rem] relative pr-5">
              <span class="text-[1.8rem] whitespace-nowrap">{{
                pkg?.network_hashrate
                }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="">

      </div> -->
    </div>

    <div class="p-4 coint-info">
      <div class="flex justify-between">
        <span class="text-secondary text-[1.4rem]">Remaining prices</span>
        <span class="text-active text-[1.4rem]">${{ pkg?.remaining_price ??  pkg?.price}}</span>
      </div>
      <div class="flex justify-between">
        <span class="text-secondary text-[1.4rem]">Duration</span>
        <span class="text-active text-[1.4rem]">~{{ pkg?.duration }} {{ pkg?.type == 1 ? 'hour' : 'day' }}</span>
      </div>
      <div class="flex justify-between">
        <span class="text-secondary text-[1.4rem]">Daily rewards</span>
        <span class="text-active text-[1.4rem]">~${{ pkg?.rewards }}</span>
      </div>
      <div class="flex justify-between">
        <span class="text-secondary text-[1.4rem]">Luck</span>
        <span class="text-active text-[1.4rem]">{{ pkg?.luck }}%</span>
      </div>
      <div class="flex justify-between">
        <span class="text-secondary text-[1.4rem]">Status</span>
        <span class="text-active text-[1.4rem] flex items-center">
          <i class="mr-2 text-active text-[0.8rem] fa-solid fa-circle"></i>
          {{
            pkg.status == 1 ? "Active" : pkg.status == 2 ? "Sold " : "Expired"
          }}</span>
      </div>
    </div>

    <div v-if="is_buy" class="flex p-5 justify-between">
      <button @click="detail" class="w-[48%] border border-coin rounded-[10px] h-[35px] p-2">
        Detail
      </button>
      <button @click="isShowPopup = true" class="w-[48%] border border-coin bg-primary rounded-[10px] p-2">
        Buy
      </button>
    </div>
    <PopupConfirm v-if="isShowPopup" @close="isShowPopup = false" @confirm="buy" title="Buy Package"
      text="Are you sure you want to buy?" type="buy" />
  </div>
</template>

<script>
import PopupConfirm from '@/components/PopupConfirm.vue'
import network from '@/network/network';

export default {
  components: { PopupConfirm },
  props: {
    is_buy: {
      type: Boolean,
      default: false,
    },
    is_sell: {
      type: Boolean,
      default: false,
    },
    pkg: {
      type: Object,
    },
  },
  data() {
    return {
      isShowPopup: false,
    }
  },
  methods: {
    buy() {
      this.$emit("buy", { id: this.pkg._id });
      this.isShowPopup = false;
    },
    detail() {
      this.$emit("detail", this.pkg);
    },
    loadImages(src) {
      return network.getImg(src);
    },
  },
};
</script>

<style></style>