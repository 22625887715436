<template>
  <div class="bg-gray-900 p-4 rounded mt-4 text-white">
    <h2 class="text-xl font-bold mb-4">Edit Machine</h2>
    <div v-if="machine">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label class="block text-sm text-white mb-1">Name</label>
          <input v-model="machine.name" type="text" placeholder="Name" class="border p-2 bg-gray-700 text-white w-full" />
        </div>
        <div>
          <label class="block text-sm text-white mb-1">Icon URL</label>
          <input v-model="machine.icon" type="text" placeholder="Icon URL" class="border p-2 bg-gray-700 text-white w-full" />
        </div>
        <div>
          <label class="block text-sm text-white mb-1">Pool Hashrate</label>
          <input v-model="machine.pool_hashrate" type="text" placeholder="Pool Hashrate" class="border p-2 bg-gray-700 text-white w-full" />
        </div>
        <div>
          <label class="block text-sm text-white mb-1">Pool Fee</label>
          <input v-model="machine.pool_fee" type="text" placeholder="Pool Fee" class="border p-2 bg-gray-700 text-white w-full" />
        </div>
        <div>
          <label class="block text-sm text-white mb-1">Workers Online</label>
          <input v-model="machine.workers_online" type="text" placeholder="Workers Online" class="border p-2 bg-gray-700 text-white w-full" />
        </div>
        <div>
          <label class="block text-sm text-white mb-1">Miners Online</label>
          <input v-model="machine.miners_online" type="text" placeholder="Miners Online" class="border p-2 bg-gray-700 text-white w-full" />
        </div>
        <div>
          <label class="block text-sm text-white mb-1">Block Rewards</label>
          <input v-model="machine.block_rewards" type="text" placeholder="Block Rewards" class="border p-2 bg-gray-700 text-white w-full" />
        </div>
        <div>
          <label class="block text-sm text-white mb-1">Network Hashrate</label>
          <input v-model="machine.network_hashrate" type="text" placeholder="Network Hashrate" class="border p-2 bg-gray-700 text-white w-full" />
        </div>
        <div>
          <label class="block text-sm text-white mb-1">Block</label>
          <input v-model="machine.block" type="text" placeholder="Block" class="border p-2 bg-gray-700 text-white w-full" />
        </div>
        <div>
          <label class="block text-sm text-white mb-1">Luck</label>
          <input v-model="machine.luck" type="text" placeholder="Luck" class="border p-2 bg-gray-700 text-white w-full" />
        </div>
        <div>
          <label class="block text-sm text-white mb-1">Rewards</label>
          <input v-model="machine.rewards" type="text" placeholder="Rewards" class="border p-2 bg-gray-700 text-white w-full" />
        </div>
        <div>
          <label class="block text-sm text-white mb-1">Duration</label>
          <input v-model="machine.duration" type="text" placeholder="Duration" class="border p-2 bg-gray-700 text-white w-full" />
        </div>
        <div>
          <label class="block text-sm text-white mb-1">Network Difficulty</label>
          <input v-model="machine.network_difficulty" type="text" placeholder="Network Difficulty" class="border p-2 bg-gray-700 text-white w-full" />
        </div>
        <div>
          <label class="block text-sm text-white mb-1">Type</label>
          <input v-model="machine.type" type="text" placeholder="Type" class="border p-2 bg-gray-700 text-white w-full" />
        </div>
        <div>
          <label class="block text-sm text-white mb-1">Status</label>
          <input v-model="machine.status" type="text" placeholder="Status" class="border p-2 bg-gray-700 text-white w-full" />
        </div>
        <div>
          <label class="block text-sm text-white mb-1">Price</label>
          <input v-model="machine.price" type="text" placeholder="Price" class="border p-2 bg-gray-700 text-white w-full" />
        </div>
        <div>
          <label class="block text-sm text-white mb-1">Probability</label>
          <input v-model="machine.probability" type="text" placeholder="Probability" class="border p-2 bg-gray-700 text-white w-full" />
        </div>
        <div>
          <label class="block text-sm text-white mb-1">Potential Block Reward</label>
          <input v-model="machine.potential_block_reward" type="text" placeholder="Potential Block Reward" class="border p-2 bg-gray-700 text-white w-full" />
        </div>
        <div class="md:col-span-2">
          <label class="block text-sm text-white mb-1">Description</label>
          <textarea v-model="machine.description" placeholder="Description" class="border p-2 bg-gray-700 text-white w-full" rows="4"></textarea>
        </div>
      </div>
      <div class="mt-4">
        <button @click="updateMachine" class="bg-green-500 text-white p-2 rounded">Update</button>
        <button @click="cancelEdit" class="bg-gray-500 text-white p-2 rounded ml-2">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import network from '@/network/network';

export default {
  name: 'AddE',
  data() {
    return {
      machine: null
    };
  },
  created() {
    this.fetchMachineData();
  },
  methods: {
    async fetchMachineData() {
      try {
        const id = this.$route.params.id; // Assuming the route param is named 'id'
        const response = await network.get(`/package/${id}`);
        this.machine = response.data.metaData;
      } catch (error) {
        console.error("Error fetching machine data:", error);
        this.$toast.open({
          message: "Error fetching machine data",
          type: "error"
        });
      }
    },
    updateMachine() {
      const id = this.$route.params.id;
      network.put(`package/${id}`, this.machine).then(res => {
        this.$toast.open({
          message: res.data.message,
          type: "success"
        });
        this.$router.push('/admin/machine'); // Redirect to the home page after successful update
      }).catch(error => {
        console.error("Error updating machine:", error);
        this.$toast.open({
          message: error.response.data.message,
          type: "error"
        });
      });
    },
    cancelEdit() {
      this.$emit('cancel-edit');
    }
  }
};
</script>

<style scoped>
.grid-cols-2 > * {
  min-width: 0; /* Fix for overflowing content in flexbox */
}
</style>
