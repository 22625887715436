<template>
    <div class="flex justify-between h-[7rem] pb-5">
        <div class="mt-1">
            <div class="text-[1.6rem]"><h1 class="text-seconday">Server Time </h1></div>
            <p class="text-[1.6rem]" id="current-datetime">{{ currentDateTime }}</p>
        </div>
        <div class="flex items-center">
            <div class="mr-4">
                <img class="h-[5rem] rounded-lg w-[5rem] h-[5rem]" width="50" height="50" :src="getAvatar(profile?.user_info?.avatar)" alt="">
            </div>
            <div class="">
                <div class="flex items-center"> 
                    <h1 class="text-[1.8rem] mr-[8rem] font-bold">{{ profile?.user_info?.username }}</h1>
                    <span>
                        <i class="fa-solid fa-gear text-gray-500 text-[2.8rem]"></i>
                    </span>
                </div>
                <p class="text-secondary">{{ profile?.user_info?.email }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import network from '@/network/network';

export default {
    name: 'HeaderApp',
    data() {
        return {
            profile: {},
            currentDateTime: ''
        }
    },
    mounted() {
        this.getProfile();
        this.updateDateTime();
        setInterval(this.updateDateTime, 1000); // Cập nhật mỗi giây
    },
    methods: {
        toPath(path) {
            this.$router.push(path); 
        },
        getProfile() {
            network.get("user/info").then((res) => {
                const data = res.data.metaData;
                this.profile = data;
            });
        },
        getAvatar(src) {
            return network.getImg(src);
        },
        updateDateTime() {
            const now = new Date();
            const year = now.getUTCFullYear();
            const month = String(now.getUTCMonth() + 1).padStart(2, '0');
            const day = String(now.getUTCDate()).padStart(2, '0');
            const hours = String(now.getUTCHours()).padStart(2, '0');
            const minutes = String(now.getUTCMinutes()).padStart(2, '0');
            const seconds = String(now.getUTCSeconds()).padStart(2, '0');
            
            this.currentDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds} UTC`;
        }
    }
}
</script>

<style>
/* Add your styles here if needed */
</style>
