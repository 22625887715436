<template>
  <div class="md:absolute top-0 right-[28rem] flex justify-center mb-5">
    <button @click="show_withdraw_popup = true"
      class="flex items-center py-5 px-20 bg-card border border-blue-800 rounded-[1rem]">
      <img src="@/assets/images/withdraw.png" width="24" alt="" />
      Withdraw
    </button>
    <button @click="show_deposite_popup = true"
      class="flex items-center py-5 px-20 bg-card border border-blue-800 rounded-[1rem] ml-10">
      <img src="@/assets/images/deposit.png" width="24" alt="" />
      Deposit
    </button>
  </div>

  <div class="pr-5 overflow-auto lg:overflow-hidden lg:h-[92vh] h-[80vh]" >
    <FlucuaInfo :profile="profile" />

    <section class="lg:flex justify-between">
      <div class="lg:w-[25%] w-full mt-5">
        <div class="bg-refer rounded-[2rem] px-5 py-5 relative text-center">
          <div class="pb-5" style="border-bottom: 1px solid rgb(171 182 189)">
            <h1 class="text-[2.1rem] font-semibold">Invite Friends and Earn Rewards!</h1>
            <div class="text-[1.2rem] font-regular text-secondary leading-relaxed mb-3">
              <ul class="list-disc list-inside ">
                <li>Earn $1 for each friend who signs up.</li>
                <li>Receive a percentage of their package purchases.</li>
              </ul>
            </div>
          </div>
          <ul class="list-disc list-inside">
          </ul>
          <div>
            <button @click="copyText(profile.referrer?.refer_code)"
              class="rounded-[1.6rem] p-5 bg-primary w-[60%] mt-5 font-semibold">
              Copy Referral Link
            </button>
          </div>
        </div>

        <div class="card rounded-[2rem] px-10 py-5 mt-5 flex flex-col justify-between items-center">
          <div class="relative flex w-ful h-[10rem]">
            <img class="z-5 mx-auto" src="@/assets/images/Balance.png" alt="" />
            <div class="top-5 left-10 absolute z-10">
              <span class="text-[1.6rem] text-[#E9EDF7]">The total amount earned from referrals.</span>
              <h1 class="text-[2rem] font-bold">$ {{ totalRefer() }}</h1>
            </div>
          </div>

          <!-- Conditional display based on totalRefer() -->
          <div v-if="this.new_user.length > 0" class="p-5 w-full">
            <transition name="fade" mode="out-in">
              <div :key="currentUser._id" class="flex items-center justify-between mt-5">
                <div class="flex items-center">
                  <span><i
                      class="px-5 py-6 mr-2 text-active bg-[#1A223F] rounded-[50%] text-[1.8rem] fa-solid fa-user-tie-hair"></i></span>
                  <div>
                    <h1 class="text-[1.6rem] font-medium py-1">New referral - {{ currentUser.user_reference_id.username }}</h1>
                    <span class="font-medium text-secondary">{{ formattedDate(`${currentUser.createdAt}`) }}</span>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>


      <!-- Deposit poup -->
      <div class="md:w-[24%] w-full mt-5 card p-8 rounded-[2rem] relative">
        <div class="flex items-center justify-between pb-5 mb-5" style="border-bottom: 1px solid rgb(6 43 65)">
          <div class="text-[1.8rem] font-bold">Deposit</div>
          <div class="flex items-center">
            <span class="px-3 py-2 bg-coin rounded-[0.7rem] mr-1">{{ time_expired_deposit.hour }} </span>:
            <span class="px-3 py-2 bg-coin rounded-[0.7rem] ml-1">{{ time_expired_deposit.minute }}
            </span>
          </div>
          <div>
            <!-- <button><i class="p-3 rounded-[1rem] bg-coin mr-5 fa-regular fa-copy"></i></button> -->
            <!-- <button><i class="p-3 rounded-[1rem] bg-coin mr-5 fa-solid fa-share-nodes"></i></button> -->
            <button @click="cancelRequest()" class="py-3 px-5 rounded-[1rem] text-[1.6rem] bg-[#052E6D]">
              Cancel
            </button>
          </div>
        </div>

        <div>
          <div class="font-medium">
            <span class="text-secondary">Network: </span>{{ wallet?.wallet_id?.name }}
          </div>
          <div class="font-medium flex justify-between whitespace-nowrap mt-2">
            <div class="max-w-[95%] overflow-hidden text-ellipsis">
              <span class="text-secondary">Address: </span>{{ wallet?.wallet_id?.address }}
            </div>
            <div>
              <span><i @click="copyText(wallet?.wallet_id?.address)"
                  class="py-3 px-4 rounded-[1rem] bg-[#052E6D] fa-regular fa-copy"></i></span>
            </div>
          </div>
          <div class="font-medium">
            <span class="text-secondary">Minimum Deposit: </span>20
          </div>
        </div>

        <div class="mt-10 flex justify-center relative bg-[#052E6D] p-16 rounded-[1.6rem] w-fit mx-auto">
          <div class="absolute top-5">
            <span class="text-[1rem] font-medium text-secondary">One time only use</span>
          </div>
          <div class="absolute -left-8 top-[45%] -rotate-90">
            <span class="text-[1rem] font-medium text-secondary">One time only use</span>
          </div>
          <div class="absolute -right-8 top-[45%] rotate-90">
            <span class="text-[1rem] font-medium text-secondary">One time only use</span>
          </div>
          <div class="absolute bottom-5">
            <span class="text-[1rem] font-medium text-secondary">One time only use</span>
          </div>
          <img class="rounded-[1.6rem]" width="100" :src="loadImages(wallet?.wallet_id?.qr_code)" alt="" />
        </div>

        <div v-if="!wallet?._id"
          class="absolute backdrop-blur rounded-[2.4rem] inset-0 bg-opacity-20 flex items-center justify-center overlay bg-blue-950 z-20 w-[full] h-[full]">
          <button @click="show_deposite_popup = true" class="w-90% bg-primary px-10 py-5 rounded-[1rem] font-semibold">
            Create Deposit Request
          </button>
        </div>
      </div>

      <div class=" w-full md:w-[49%] mt-5 card px-10 py-5 rounded-[2rem] text-[1.8rem] font-bold flex justify-center items-end">
        <div class="w-[90%]">
          <ChartAmountEarned class="w-full" />
        </div>
      </div>

    </section>

    <section class="flex flex-wrap justify-between ">
      <div class="mt-5 card rounded-[2rem] w-[100%] h-[34vh] lg:h-auto p-5 ">
        <HistoryRewardWallet />
      </div>
    </section>

    <div v-if="show_withdraw_popup || show_deposite_popup"
      class="absolute backdrop-blur inset-0 bg-opacity-20 flex items-center justify-center overlay bg-blue-950 z-20 w-[full] h-[full]">
    </div>
    <div v-if="show_withdraw_popup" class="absolute inset-0 flex items-center justify-center z-30">
      <div class="w-[100rem] box p-5 relative">
        <div class="flex bg-[#034EAD] items-center rounded-[1.6rem] p-5 m-5">
          <div class="w-[20%]">
            <img src="@/assets/images/usdt_icon.png" width="80" alt="" />
          </div>
          <div class="w-[80%] text-center pr-20 text-[3.2rem] font-bold">
            Withdraw
          </div>

          <button @click="show_withdraw_popup = false"
            class="absolute top-[0] -translate-y-2 translate-x-2 right-0 text-[2.4rem] font-medium px-5 py-0 bg-red-500 rounded-[1rem]">
            X
          </button>
        </div>
        <div class="overflow-y-auto scroll m-5 pr-5 md:h-[55vh] h-[40vh]" >

          <div class="mt-5 pb-5" style="border-bottom: 1px solid rgb(6 43 65)">
            <h1 class="text-[2.4rem] font-bold">Network</h1>
            <span>Please select the network we support for withdrawal</span>
            <v-select :options="networkType" :reduce="c => c.value" label="text"
              class="w-full text-[2rem] outline-none font-medium p-5 bg-[#0A295F] rounded-[1rem] mt-10"
              v-model="withdraw.type"></v-select>

            <!-- <select
              v-model="withdraw.type"
              class="w-full text-[2rem] outline-none font-medium p-5 bg-[#0A295F] rounded-[1rem] mt-10"
            >
              <option disabled selected>Select Network</option>
              <option value="1">BSC</option>
              <option value="2">TRC</option>
            </select> -->
          </div>

          <div class="mt-5 pb-5" style="border-bottom: 1px solid rgb(6 43 65)">
            <h1 class="text-[2.4rem] font-bold">Address</h1>
            <div class="text-[2rem] font-medium p-5 bg-[#0A295F] rounded-[1rem] mt-10">
              <input v-model="withdraw.address"
                class="text-[2rem] outline-none font-medium text-secondary bg-[#0A295F] w-[80%]"
                placeholder="Please enter wallet address" />
            </div>
          </div>


          <div class="mt-5 pb-5" style="border-bottom: 1px solid rgb(6 43 65)">
            <h1 class="text-[2.4rem] font-bold">Withdrawal amount</h1>
            <div class="bg-[#0A295F] rounded-[1rem] mt-10 flex justify-between items-center">
              <input class="text-[2rem] outline-none font-medium text-secondary pl-5 bg-[#0A295F] w-[80%]"
                placeholder="Minimum 20" v-model="withdraw.amount" />
              <div class="flex items-center">
                <span class="mr-10 text-[2.4rem] font-bold">USDT</span>
                <button class="px-10 bg-primary py-5 rounded-[1rem] text-[2.5rem] font-medium"
                  @click="withdraw.amount = profile.wallet_amount">
                  Max
                </button>
              </div>
            </div>
            <div class="flex justify-between mt-5">
              <span class="text-active">Avaliable</span>
              <span class="text-[1.8rem]">{{profile.wallet_amount}} USDT</span>
            </div>
          </div>

          <div class="mt-5 pb-5" style="border-bottom: 1px solid rgb(6 43 65)">
            <h1 class="text-[2.4rem] font-bold">Descriptions</h1>
            <p class="text-[1.6rem] text-secondary">
              Make sure the network matches the withdrawal address and the deposit platform supports the coin you intend to withdraw, otherwise you will lose your assets.
            </p>
          </div>
        </div>

        <div class="flex p-5 justify-between">
          <button @click="closePopup()" class="w-[48%] border border-primary rounded-[10px] p-3 text-[2rem]">
            Close
          </button>
          <button class="w-[48%] border border-primary bg-primary rounded-[10px] p-3 text-[2rem]"
            @click="withdrawConfirm()">
            Withdraw
          </button>
        </div>
      </div>
    </div>

    <div v-if="show_deposite_popup" class="absolute inset-0 flex items-center justify-center z-30">
      <div class="md:w-[100rem] w-[350px] box p-5 relative">
        <div class="flex bg-[#034EAD] items-center rounded-[1.6rem] p-5 m-5">
          <div class="w-[20%]">
            <img src="@/assets/images/usdt_icon.png" width="80" alt="" />
          </div>
          <div class="w-[80%] text-center pr-20 text-[3.2rem] font-bold">
            Deposit
          </div>

          <button @click="closePopup()"
            class="absolute top-[0] -translate-y-2 translate-x-2 right-0 text-[2.4rem] font-medium px-5 py-0 bg-red-500 rounded-[1rem]">
            X
          </button>
        </div>
        <div v-if="!isConfirmDeposit" class="overflow-y-auto scroll m-5 pr-5 " >
          <div class="mt-5 pb-5" style="border-bottom: 1px solid rgb(6 43 65)">
            <h1 class="text-[2.4rem] font-bold">Network</h1>
            <p class="text-[1.6rem] text-secondary">
              Please select one of the supported networks below
            </p>
            <span>Minimum 20 USDT</span>
            <v-select :options="networkType" :reduce="c => c.value" label="text"
              
              class="w-full text-[2rem] outline-none font-medium p-5 bg-[#0A295F] rounded-[1rem] mt-10"
              v-model="deposit.type"></v-select>
          </div>


          <div class="mt-5 pb-5" style="border-bottom: 1px solid rgb(6 43 65)">
            <h1 class="text-[2.4rem] font-bold">Descriptions</h1>
            <p class="text-[1.6rem] text-secondary">
              This is the reward you will get for one signed block, paid to your
              Walleet. If you find more, the reward can be even higher. You
              can also have the reward paid out to an external wallet instead by
              checking the box below. Read more about claiming rewards here.
            </p>
          </div>
        </div>

        <!-- XAC THUC -->
        <div v-else class="md:flex justify-between px-5 overflow-y-auto h-[50vh]">
          <div
            class="md:w-[30rem] min-w-[30rem] h-[30rem] w-fit  md:mr-10 mt-10 flex justify-center relative bg-[#052E6D] p-16 rounded-[1.6rem] mx-auto">
            <div class="absolute top-5">
              <span class="text-[1rem] font-medium text-secondary">One time only use</span>
            </div>
            <div class="absolute -left-8 top-[45%] -rotate-90">
              <span class="text-[1rem] font-medium text-secondary">One time only use</span>
            </div>
            <div class="absolute -right-8 top-[45%] rotate-90">
              <span class="text-[1rem] font-medium text-secondary">One time only use</span>
            </div>
            <div class="absolute bottom-5">
              <span class="text-[1rem] font-medium text-secondary">One time only use</span>
            </div>
            <img class="rounded-[1.6rem]" width="206" :src="loadImages(wallet?.wallet_id?.qr_code)" alt="" />
          </div>

          <div class="mt-10">
            <div class="flex items-center justify-center">
              <span class="px-5 py-2 text-[2.4rem] bg-coin rounded-[0.7rem] mr-3">{{ time_expired_deposit.hour }}
              </span>:
              <span class="px-5 py-2 text-[2.4rem] bg-coin rounded-[0.7rem] ml-3">{{ time_expired_deposit.minute }}
              </span>
            </div>
            <div>
              <div class="font-medium">
                <span class="text-secondary">Network: </span>{{ wallet?.wallet_id?.name }}
              </div>
              <div class="font-medium flex justify-between whitespace-nowrap mt-2">
                <div>
                  <span class="text-secondary">Address: </span>{{ wallet?.wallet_id?.address }}
                </div>
                <div>
                  <span @click="copyText(wallet?.wallet_id?.address)"><i
                      class="py-3 px-4 rounded-[1rem] bg-[#052E6D] fa-regular fa-copy"></i></span>
                </div>
              </div>
              <div class="font-medium">
                <span class="text-secondary">Minimum Deposit: </span>20
              </div>
            </div>

            <div class="mt-10">
              <div class="text-[1.6rem] text-secondary">
                <i class="fa-solid fa-circle text-[0.3rem] text-secondary"></i>
                The above is a TRX address. Do not transfer USDT (TRC20) or any
                non-TRX assets to the address above or the money cannot be
                retrieved
              </div>
              <div class="text-[1.6rem] text-secondary">
                <i class="fa-solid fa-circle text-[0.3rem] text-secondary"></i>
                After the payment is made, the entire network encryption
                security is needed to confirm. Please wait patiently
              </div>
              <div class="text-[1.6rem] text-secondary">
                <i class="fa-solid fa-circle text-[0.3rem] text-secondary"></i>
                Do not deposit below the minimum deposit amount
              </div>
              <div class="text-[1.6rem] text-secondary">
                <i class="fa-solid fa-circle text-[0.3rem] text-secondary"></i>
                When the recharge amount exceeds the minimum deposit amount,
                deposits below the minimum amount will be automatically credited
                to the account
              </div>
              <div class="text-[1.6rem] text-secondary">
                <i class="fa-solid fa-circle text-[0.3rem] text-secondary"></i>
                1Reminder, the validity period of scanning the code on this page
                is 72 hours. The address will automatically expire when the time
                limit expires. Do not store the scan code address for recharge
                by yourself.
              </div>
            </div>
          </div>
        </div>

        <div class="flex p-5 justify-between">
          <button @click="show_deposite_popup = false"
            class="w-[48%] border border-primary rounded-[10px] p-3 text-[2rem]">
            Close
          </button>
          <button class="w-[48%] border border-primary bg-primary rounded-[10px] p-3 text-[2rem]"
            @click="depositContinute" v-if="!isConfirmDeposit">
            Continute
          </button>
          <button v-else class="w-[48%] border border-primary bg-primary rounded-[10px] p-3 text-[2rem]"
            @click="depositConfirm">
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
  <Loading v-if="is_loading" />
</template>

<script>
import network from "@/network/network";
import Loading from "@/components/Loading.vue";
import FlucuaInfo from "@/components/FlucuaInfo.vue";
// import network from '@/network/network';
import HistoryRewardWallet from "@/components/HistoryRewardWallet.vue";
import ChartAmountEarned from "@/components/ChartAmountEarned.vue";
import { format } from "date-fns";

export default {
  components: { Loading, FlucuaInfo, HistoryRewardWallet, ChartAmountEarned },
  data() {
    return {
      is_loading: false,
      isConfirmDeposit: false,
      profile: {},
      time_expired_deposit: {
        hour: "00",
        minute: "00",
      },
      invests: [1, 2, 3, 4],
      sliders: [1, 2, 3, 4, 5],
      show_withdraw_popup: false,
      show_deposite_popup: false,
      wallet: {},
      deposit: {
        type: 0,
        amount: null,
      },
      withdraw: {
        type: null,
        amount: null,
        address: null,
      },
      options: {
        rewind: true,
        autoplay: true,
        padding: "10%",
        type: "loop",
        arrows: false,
      },
      dataTable: [1, 2, 3, 4, 5, 6, 7, 8],
      ranks: [1, 2, 3, 4, 5, 6, 7, 8, 10, 11],
      historyIncome: [1, 2, 3, 4, 5, 6],
      networkType: [
        { value: 1, text: 'BSC' },
        { value: 2, text: 'TRC' }
      ],
      new_user: [],
      currentIndex: 0
    };
  },
  computed: {
    currentUser() {
      return this.new_user[this.currentIndex];
    }
  },
  mounted() {
    this.getProfile();
    this.startCountdown();
    setInterval(this.nextUser, 5000);
  },
  methods: {
    depositContinute() {
      this.is_loading = true;

      network
        .post("deposit/request", this.deposit)
        .then((res) => {
          this.$toast.open({
            message: res.data.message,
            type: "success",
          });
          this.wallet = res.data.metaData;
          this.is_loading = false;
          this.isConfirmDeposit = true;
        })
        .catch((err) => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
          });
          this.is_loading = false;
        });
    },
    depositConfirm() {
      this.closePopup();
    },
    withdrawConfirm() {
      this.is_loading = true;

      network
        .post("withdraw/request", this.withdraw)
        .then((res) => {
          this.$toast.open({
            message: res.data.message,
            type: "success",
          });
          this.is_loading = false;
          this.closePopup();
        })
        .catch((err) => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
          });
          this.is_loading = false;
        });
    },
    getListCoin() {
      network
        .get("crypto")
        .then((res) => {
          this.cryptoList = res.data.metaData;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProfile() {
      this.is_loading = true;
      network.get("user/info").then((res) => {
        const data = res.data.metaData;
        this.wallet = data.deposit_request;
        this.profile = data;
        this.is_loading = false;
        this.new_user = data.referrer.new_user;
      });
    },
    nextUser() {
      this.currentIndex = (this.currentIndex + 1) % this.new_user.length;
    },
    formatNumber(num) {
      return Number(num)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formattedNumber(num) {
      return Math.floor(num * 100) / 100
    },
    closePopup() {
      this.show_withdraw_popup = false;
      this.show_deposite_popup = false;
      this.deposit.type = null;
      this.deposit.amount = null;
      this.isConfirmDeposit = false;
    },
    loadImages(src) {
      return network.getImg(src);
    },
    totalRefer() {
      return this.formattedNumber(this.profile.referrer?.total_earned ?? 0) + "/" + this.profile.referrer?.total_user_referred
    },
    userRefer() {
      return this.formatNumber(this.profile.referrer?.total_user_referred ?? 0);
    },
    totalPercentReward() {
      const soloPercentChange = this.profile?.solo?.solo_percent_change ?? 0;
      const poolPercentChange = this.profile?.pool?.pool_percent_change ?? 0;
      const totalPercentChange =
        Number(soloPercentChange) + Number(poolPercentChange);
      return this.formatNumber(totalPercentChange);
    },

    totalDailyEarn() {
      const totalDailyEarn =
        this.profile?.solo?.solo_total_earned +
        this.profile.pool?.pool_total_earned;
      return this.formatNumber(totalDailyEarn);
    },
    copyText(text) {
      const currentUrl = window.location.origin;
      const textArea = document.createElement("textarea");
      textArea.value = currentUrl + '/r/' +  text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      this.$toast.open({
        message: "Copied",
        type: "success",
      });
    },
    formatTime(time) {
      return time < 10 ? `0${time}` : time;
    },
    formattedDate(timestamp) {
      const date = new Date(timestamp);
      if (isNaN(date)) {
        return "Invalid date";
      }
      return format(date, "dd MMMM yyyy, 'at' hh:mm a");
    },
    startCountdown() {
      setInterval(() => {
        if (this.wallet) {
          const currentDate = new Date(); // Current date and time in UTC
          const expiredTime = new Date(this.wallet.expired_time); // Assuming this.wallet.expired_time is a valid date string or Date object

          // Calculate the difference in milliseconds
          const distance = expiredTime.getTime() - currentDate.getTime();

          if (distance > 0) {
            const hours = Math.floor(
              (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor(
              (distance % (1000 * 60 * 60)) / (1000 * 60)
            );
            this.time_expired_deposit.hour = this.formatTime(hours);
            this.time_expired_deposit.minute = this.formatTime(minutes);
          } else {
            this.time_expired_deposit.hour = "00";
            this.time_expired_deposit.minute = "00";
          }
        }
      }, 1000);
    },
    cancelRequest() {
      this.is_loading = true;
      network.post("deposit/request/cancel", { request_id: this.wallet._id }).then((res) => {
        this.$toast.open({
          message: res.data.message,
          type: "success",
        });
        this.getProfile();
        this.is_loading = false;
      }).catch(err => {
        this.$toast.open({
          message: err.response.data.message,
          type: "error",
        });
        this.is_loading = false;
      });
    }
  },
  name: "HomePage",
};
</script>

<style scoped>
@media screen and (max-width: 2540px) {
  ::v-deep .splide__list {
    max-width: 600px !important;
  }

  .rotating-card {
    height: 100%;
  }

  .rotating-card img {
    width: 80%;
    /* height: 100%; */
    object-fit: cover;
    z-index: 2;
    animation: rotate 5s linear infinite;
    top: 20%;
  }
}

@media screen and (min-width: 2540px) and (max-width: 3076px) {
  ::v-deep .splide__list {
    max-width: 600px !important;
  }

  .rotating-card {
    height: 100%;
  }

  .rotating-card img {
    width: 40%;
    /* height: 100%; */
    object-fit: cover;
    z-index: 2;
    animation: rotate 5s linear infinite;
    top: 20%;
  }
}

@media screen and (max-width: 102.4rem) {}

@media screen and (max-width: 776px) {
  .rotating-card img {
    width: 30% !important;
    /* height: 100%; */
    object-fit: cover;
    z-index: 2;
    animation: rotate 5s linear infinite;
    margin-top: -5%;
    /* top: 10%; */
  }

  .respon {
    margin-top: 10px;
  }

  .time-remain {
    height: 500px;
  }
}

.splide__list .splide__slide {
  opacity: 0.2;
  /* Mờ 90% */
  transition: opacity 0.3s ease;
  /* Thêm hiệu ứng chuyển đổi mượt mà */
}

.splide__list .splide__slide.is-active {
  opacity: 1;
  /* Hiển thị rõ phần tử có class is-active */
}

.progress {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rank .list-rank {
  border-bottom: 1px solid rgb(6 43 65);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

::v-deep .vs__dropdown-menu {
  background: #042968;
  border-radius: 5px;
  margin-top: 1rem;
  padding: 0;
}

::v-deep .v-select,
::v-deep .vs__selected {
  color: #fff;
}

::v-deep .vs--searchable .vs__dropdown-toggle {
  border: none;
}

::v-deep .vs__dropdown-menu::-webkit-scrollbar {
  width: 5px;
  /* Chiều rộng của thanh cuộn */

}

::v-deep .vs__dropdown-menu::-webkit-scrollbar-track {
  background-color: #161e5c;
  /* Màu nền của thanh cuộn */
}

::v-deep .vs__dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #21339b;
  /* Màu của nút thanh cuộn */
  border-radius: 6px;
  /* Độ cong của nút thanh cuộn */
}

::v-deep .vs__dropdown-menu::-webkit-scrollbar-thumb:hover {
  background-color: rgb(18, 17, 90);
  /* Màu của nút thanh cuộn khi hover */
}

::v-deep .vs__dropdown-option {
  padding: 10px;
}

/* news User */

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

</style>