<template>
  <div class="pr-5  h-[100vh] overflow-auto md:overflow-hidden ">
    <section class="w-full flex justify-between items-center px-10 py-5 bg-refer rounded-[2rem]">
      <div class="flex items-center">
        <div class="mr-4">
          <img width="50" height="50" src="@/assets/images/avt.png" alt="" />
        </div>
        <div class="">
          <div class="flex items-center">
            <h1 class="text-[1.8rem] mr-[3.5rem]">{{ profile?.username }}</h1>
            <span>
              <!-- <i class="fa-solid fa-gear text-gray-500 text-[2.8rem]"></i> -->
            </span>
          </div>
          <p class="text-seconday">{{ profile?.email }}</p>
        </div>
        <div></div>
      </div>
      <div>
        <button class="uppercase px-10 py-3 bg-primary rounded-[1.6rem]">
          <i class="fa-solid fa-box-open mr-2"></i>overview
        </button>
      </div>
    </section>

    <section class="flex flex-wrap justify-between" v-if="is_my_pofile">
      <div class="mt-5 lg:w-[38%] md:w-[70%] sm:w-full m-w-[40%] box rounded-[2rem]">
        <div class="px-10 py-5">
          <div class="flex justify-between">
            <h1 class="text-[10.8remem] font-bold">
              Remaining packages
            </h1>
            <div class="text-secondary">
              <!-- Total hash rate: <span>18.80 TH/s</span> -->
            </div>
          </div>
          <div class="flex justify-between">
            <div class="text-secondary flex items-center">
              <i class="fa-regular text-[1.6rem] fa-calendar-days mr-2"></i>
              23-30 March 2020
            </div>
            <div class="text-secondary">Block: <span>1036820</span></div>
          </div>
        </div>
        <div class="px-10 py-5 box rounded-[2rem]">
          <Splide :options="options" aria-label="My Favorite Images">
            <SplideSlide class="pb-10" v-for="(image, index) in sliders" :key="index">
              <div class="card p-5 rounded-[2rem]">
                <div class="flex card-m justify-between px-3 py-5 rounded-[2rem]">
                  <div class="flex">
                    <div>
                      <img class="w-[60px] px-2 mr-2" src="@/assets/images/dog.png" alt="" />
                    </div>
                    <div class="items-center">
                      <div class="text-[2rem]">DOGECOIN</div>
                      <div class="flex justify-between items-center">
                        <div class="text-secondary">Hashrate: 18.70 TH/s</div>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <div class="text-active flex items-center justify-center mb-4">
                      <i class="mr-2 text-active text-[0.8rem] fa-solid fa-circle"></i>
                      Running
                    </div>
                    <div class="text-secondary whitespace-nowrap">
                      Miners: <span>8186</span>
                    </div>
                  </div>
                </div>
                <div class="p-6">
                  <div class="flex justify-between">
                    <span class="text-secondary text-[1.6rem]">Potential Block Reward</span>
                    <span class="text-secondary text-[1.6rem]">Probability</span>
                  </div>
                  <div class="flex justify-between">
                    <div class="font-bold text-[10.8remem] relative">
                      ~0.02352
                      <span class="text-secondary absolute ml-1 text-[1.6rem]">USDT</span>
                    </div>
                    <div class="text-[10.8remem]">1:567</div>
                  </div>
                  <div class="flex justify-between items-center mt-3 font-medium">
                    <div class="font-bold text-[10.8remem] relative">
                      2.38
                      <span class="text-secondary absolute ml-1 text-[1.6rem]">USDT</span>
                    </div>
                    <div>
                      <button class="text-[16px] card border border-primary px-12 py-2 rounded-[10px]">
                        Detail
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <CardCoin /> -->
            </SplideSlide>
          </Splide>
        </div>
      </div>

      <div class="mt-5 box respon time-remain lg:w-[22%] md:w-[29%] sm:w-full rounded-[2rem] px-10 py-5">
        <h1 class="text-[1.8rem] font-bold">Time remaining</h1>
        <span class="text-[1.8rem] font-bold text-secondary">~4 hour</span>
        <div class="rotating-card flex justify-center relative lg:mt-10 text-center">
          <div class="relative mt-20 w-full progress">
            <div class="absolute top-[5rem] w-full z-30 flex flex-col items-center">
              <i class="fa-regular fa-face-laugh p-5 text-[2.5rem] rounded-full bg-primary text-white"></i>
              <div class="flex mt-10 card p-5 w-full rounded-[2rem] justify-between">
                <div class="text-secondary">0%</div>
                <div class="text-center">
                  <div class="text-[26px] font-bold">64%</div>
                  <div class="text-secondary">Based on likes</div>
                </div>
                <div class="text-secondary">100%</div>
              </div>
            </div>
            <div class="absolute left-1/2 transform -translate-x-1/2 z-20 w-[20rem]">
              <img width="200px" src="@/assets/images/cycle.png" alt="cycle image" />
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5 lg:w-[38%] respon md:w-[100%] sm:w-full m-w-[40%] box rounded-[2rem]">
        <div class="w-full">
          <div class="flex flex-row font-bold px-10 py-5">
            <div class="w-[20%] text-center text-[1.5rem] text-secondary">
              Date
            </div>
            <div class="w-[30%] text-center text-[1.5rem] text-secondary">
              Number and Hash
            </div>
            <div class="w-[20%] text-center text-[1.5rem] text-secondary">
              Reward
            </div>
            <div class="w-[30%] text-center text-[1.5rem] text-secondary">
              Address and Tx ID
            </div>
          </div>
          <div class="max-h-[344px] mt-0 overflow-y-auto scroll pl-4 pr-4">
            <div class="card-m p-5 rounded-[10px] mb-5" v-for="d in dataTable" :key="d">
              <div class="flex flex-row">
                <div class="w-[20%] overflow-hidden truncate text-center text-blur">
                  2m ago
                </div>
                <div class="w-[30%] overflow-hidden truncate text-center">
                  18794602 Pending
                </div>
                <div class="w-[20%] overflow-hidden truncate text-center text-active">
                  2.709677
                </div>
                <div class="w-[30%] overflow-hidden truncate text-center">
                  0x6bf2151f4f3672632579
                </div>
              </div>
              <!-- Ensure each :key has quotes around it as :key="d" -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-else class="flex flex-wrap justify-between">
      <div class="mt-5 lg:w-[55%] md:w-[70%] sm:w-full m-w-[40%] box rounded-[2rem]">
        <div class="flex justify-between px-10 py-5">
          <div>
            <h1 class="text-[2.4rem]">Overview</h1>
            <span class="text-[1.6rem]">Hello, {{ profile.username }}! Your Car is ready.</span>
          </div>
          <div class="flex items-center">
            <i class="fa-solid fa-star p-3 rounded-[1rem] bg-primary"></i>
            <span class="text-[2.4rem] ml-[0.5rem]">Ranking: {{ indexRank + 1 }}</span>
          </div>
        </div>
        <div class="md:flex block justify-between md:flex-wrap p-5 ">
          <div class="relative w-[16.7rem] h-[16.7rem] rotating-card mx-auto">
            <img class="absolute w-[15.7rem] left-6 " style="top: 0" src="@/assets/images/cycle2.png" alt="" />
            <div class="absolute w-[16.7rem] pl-10 h-[16.7rem] text-center flex items-center justify-center">
              <div>
                <h1 class="text-[2rem] font-bold">
                  ${{ data.total_daily_earned }}
                </h1>
                <span class="text-[1.6rem] text-secondary">Daily rewards</span>
              </div>
            </div>
          </div>

          <div class="flex justify-end md:w-[67%] mt-[20rem] md:mt-0">
            <div class="flex flex-wrap w-[auto] mr-5">
              <div class="flex p-5 card-m rounded-[2rem] w-[100%] mb-5 justify-between">
                <div>
                  <span class="text-[1.5rem] text-secondary">Solo</span>
                  <h1>${{ formatNumber(data.solo?.solo_total_earned) }}</h1>
                </div>
                <div>
                  <img class="w-[8.1rem]" src="@/assets/images/line-chart1.png" alt="" />
                </div>
              </div>
              <div class="flex p-5 card-m rounded-[2rem] w-[100%] mb-5 justify-between">
                <div>
                  <span class="text-[1.5rem] text-secondary whitespace-nowrap">Packages purchased</span>
                  <h1>$ {{ formatNumber(packages_purchased) }}</h1>
                </div>
                <div>
                  <img class="w-[8.1rem]" src="@/assets/images/line-chart-purch.png" alt="" />
                </div>
              </div>
            </div>
            <div class="flex flex-wrap w-[auto]">
              <div class="flex p-5 card-m rounded-[2rem] w-[100%] mb-5 justify-between">
                <div>
                  <span class="text-[1.5rem] text-secondary">Pool</span>
                  <h1>${{ formatNumber(data?.pool?.pool_total_earned) }}</h1>
                </div>
                <div class="ml-[5rem]">
                  <i class="fa-solid fa-bolt p-6 text-[2rem] bg-primary rounded-2xl"></i>
                </div>
              </div>
              <div class="flex p-5 card-m rounded-[2rem] w-[100%] mb-5 justify-between">
                <div>
                  <span class="text-[1.5rem] text-secondary whitespace-nowrap">Wallet amount</span>
                  <h1>$ {{ formatNumber(wallet_assets) }}</h1>
                </div>
                <div class="ml-[5rem]">
                  <i class="fa-solid fa-wallet p-6 text-[2rem] bg-primary rounded-2xl"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 card rounded-[2rem] md:w-[44%] p-5 md:h-[32vh] h-[30vh] w-full">
        <div class="flex justify-between px-5 pt-5">
          <ChartAmountEarned class="w-[90%]" :idOverview="$route.params.id"/>
        </div>
          
      </div>
    </section>

    <section class="flex flex-wrap justify-between ">
      <div class="mt-5 lg:w-[65%] md:w-[100%] w-[100%] box rounded-[2rem] p-6">
        <div class="px-10 py-5">
          <div class="flex justify-between">
            <h1 class="text-[10.8remem] font-bold">Remaining packages</h1>
            <div class="flex mt-10 md:mt-0">
              <button @click="changeType(2)" class="text-[3rem] font-bold uppercase mr-5"
                :class="{ 'text-secondary': type == 1 }">
                Pool
              </button>
              <span class="text-[3rem]">/</span>
              <button @click="changeType(1)" class="text-[3rem] font-bold uppercase ml-5"
                :class="{ 'text-secondary': type == 2 }">
                Solo
              </button>
            </div>
            <div class="text-secondary">
              Total earned: <span>{{ formatNumber(total_eared) }} $</span>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="text-secondary flex items-center">
              <i class="fa-regular text-[1.6rem] fa-calendar-days mr-2"></i>
              30 Packages
            </div>
            <div class="text-secondary">Assets value: <span>{{ formatNumber(assets_value) }}$</span></div>
          </div>
        </div>
        <div class="grid lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 h-[36vh] 2xl:h-[30vh] overflow-y-auto scroll">
          <CardCoinOverview v-for="i in packages" :key="i" :pkg="i.package_id"
            @click="(show_detail = true), (detail = i.package_id)" />
        </div>
      </div>

      <div class="lg:w-[34%] ">
        <div class="card rounded-[2rem] px-10 py-5 mt-5 flex flex-col justify-between items-center h-[22rem]">
          <div class="relative flex w-full">
            <img class="z-5 mx-auto" src="@/assets/images/Balance.png" alt="" />
            <div class="top-5 left-10 absolute z-10">
              <span class="text-[1.6rem] text-[#E9EDF7]">The total amount earned from referrals.</span>
              <h1 class="text-[2.8rem] font-bold">$ {{ totalRefer() }}</h1>
            </div>
          </div>

          <!-- Conditional display based on totalRefer() -->
          <div v-if="this.new_user.length > 0" class="p-5 w-full">
            <transition name="fade" mode="out-in">
              <div :key="currentUser._id" class="flex items-center justify-between">
                <div class="flex items-center">
                  <span><i
                      class="px-5 py-6 mr-2 text-active bg-[#1A223F] rounded-[50%] text-[1.8rem] fa-solid fa-user-tie-hair"></i></span>
                  <div>
                    <h1 class="text-[1.6rem] font-medium py-1">New referral - {{ currentUser.user_reference_id.username }}</h1>
                    <span class="font-medium text-secondary">{{ formattedDate(`${currentUser.createdAt}`) }}</span>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      
        <div class="mt-5 card rounded-[2rem] p-5 mb-[10rem] md:mb-0">
          <HistoryReward overview="true" :idOverview="$route.params.id" />
        </div>
      </div>
    </section>
  </div>

  <DetailCoin v-if="show_detail" :detail="detail" @close="show_detail = null" @buy="buyPackage" />
  <loading v-if="is_loading" />
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import CardCoinOverview from "@/components/CardCoinOverview.vue";
import DetailCoin from "@/components/DetailCoin.vue";
import network from "@/network/network";
import Loading from "@/components/Loading.vue";
import HistoryReward from "@/components/HistoryReward.vue";
import ChartAmountEarned from "@/components/ChartAmountEarned.vue";

import { format } from "date-fns";

export default {
  components: { Splide, SplideSlide,ChartAmountEarned, CardCoinOverview, HistoryReward, DetailCoin, Loading },
  data() {
    return {
      is_my_pofile: false,
      is_loading: false,
      data: {},
      detail: {},
      show_detail: false,
      invests: [1, 2, 3, 4],
      sliders: [1, 2, 3, 4, 5],
      packages: [],
      packages_purchased: 0,
      wallet_assets: 0,
      assets_value: 0,
      total_eared: 0,
      listPackagesData: [],
      profile: {},
      userRankings: {},
      rewards_history: {},
      referrer: {},
      indexRank: 0,
      options: {
        rewind: true,
        autoplay: true,
        padding: "10%",
        type: "loop",
        arrows: false,
      },
      type: 2,
      dataTable: [1, 2, 3, 4, 5, 6, 7, 8],
      ranks: [1, 2, 3, 4, 5, 6, 7, 8, 10, 11],
      historyIncome: [1, 2, 3, 4, 5, 6],

      pkg: {
        _id: "667130da7d175025feba2a6f",
        name: "Zeplyr Mining Pool",
        icon: "https://i.pravatar.cc/300",
        pool_hashrate: "100.01 GH/s",
        pool_fee: "1.5%",
        workers_online: "22",
        miners_online: "44",
        block_rewards: "2 BCH",
        network_hashrate: "21.11 TH/s",
        block: 103963,
        luck: 25,
        rewards: 461.15,
        duration: 2,
        network_difficulty: "507.58 G",
        type: 1,
        status: 1,
        price: 6.51,
        probability: "1:728",
        potential_block_reward: "0.00707504 BTC",
        description:
          "The most profitable Bitcoin Cash mining pool for GPU and ASIC. Regular payments, tutorials, reliable servers, rig monitoring bot.",
        createdAt: "2024-06-18T07:01:46.389Z",
        updatedAt: "2024-06-18T07:01:46.389Z",
        __v: 0,
      },
            new_user: [],
      currentIndex: 0
    };
  },
  computed: {
    currentUser() {
      return this.new_user[this.currentIndex];
    }
  },
  mounted() {
    this.getProfileAccountOverview(null);
    this.getUserRanking()
    setInterval(this.nextUser, 5000);
  },
  methods: {
    toWatchProfile(id) {
      this.$router.push(`/overview/${id}`);
      this.getProfileAccountOverview(id);
    },
    getProfileAccountOverview(id) {
      this.is_loading = true;
      const idOverview = id !== null ? id : this.$route.params.id;
      network.get("/user/overview/" + idOverview).then((res) => {
        const data = res.data.metaData;
        this.data = data;
        this.packages = data.remaining_packages;
        this.profile = data.user_info;
        this.rewards_history = data.rewards_history;
        this.referrer = data.referrer;
        this.listPackagesData = data.remaining_packages;
        this.user_pool = data.pool;
        this.user_solo = data.solo;
        this.wallet_assets = data.wallet_amount;
        this.changeType(2);
        this.calculator(data.remaining_packages)
        this.is_loading = false;
        this.new_user = data.referrer.new_user;
      });
    },
    calculator(data) {
      for (let index = 0; index < data.length; index++) {
        this.packages_purchased = this.packages_purchased + data[index]?.total_earned;

      }
    },
    changeType(type) {
      this.resetValue();
      this.type = type;
      this.packages = this.listPackagesData.filter((p) => p.type == this.type);
      for (let index = 0; index < this.packages.length; index++) {
        this.assets_value = this.assets_value + this.packages[index]?.origin_price
        this.total_eared = this.total_eared + this.packages[index]?.total_earned;
      }
    },
    resetValue() {
      this.packages_purchased = 0;
      this.wallet_assets = 0;
      this.assets_value = 0;
      this.total_eared = 0;
    },

    formattedNumber(num) {
      return Math.floor(num * 100) / 100
    },
    nextUser() {
      this.currentIndex = (this.currentIndex + 1) % this.new_user.length;
    },
    totalRefer() {
      return this.formattedNumber(this.referrer?.total_earned ?? 0) + "/" + this.referrer?.total_user_referred
    },
    formattedDate(timestamp) {
      const date = new Date(timestamp);
      if (isNaN(date)) {
        return "Invalid date";
      }
      return format(date, "dd MMMM yyyy, 'at' hh:mm a");
    },
    formatNumber(num) {
      return Number(num)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getUserRanking() {
      this.is_loading = true;
      network
        .get("user/ranking")
        .then((res) => {
          let data = res.data.metaData;
          this.userRankings = data;
          this.indexRank = this.data.findIndex(item => item._id == this.$route.params.id)
          this.is_loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.is_loading = false;
        });
    },
  },
  name: "HomePage",
};
</script>

<style scoped>
@media screen and (max-width: 2540px) {
  ::v-deep .splide__list {
    max-width: 600px !important;
  }

  .rotating-card {
    height: 100%;
  }

  .rotating-card img {
    /* width: 190pxpx; */
    /* height: 100%; */
    object-fit: cover;
    z-index: 2;
    animation: rotate 5s linear infinite;
    top: 0%;
  }
}

@media screen and (min-width: 2540px) and (max-width: 3076px) {
  ::v-deep .splide__list {
    max-width: 600px !important;
  }

  .rotating-card {
    /* height: 100%; */
  }

  .rotating-card img {
    /* width: 40%; */
    /* height: 100%; */
    object-fit: cover;
    z-index: 2;
    animation: rotate 5s linear infinite;
    top: 20%;
  }
}

@media screen and (max-width: 102.4rem) {}

@media screen and (max-width: 776px) {
  .rotating-card img {
    /* width: 30% !important; */
    /* height: 100%; */
    object-fit: cover;
    z-index: 2;
    animation: rotate 5s linear infinite;
    margin-top: -5%;
    /* top: 10%; */
  }

  .respon {
    margin-top: 10px;
  }

  .time-remain {
    height: 500px;
  }
}

.splide__list .splide__slide {
  opacity: 0.2;
  /* Mờ 90% */
  transition: opacity 0.3s ease;
  /* Thêm hiệu ứng chuyển đổi mượt mà */
}

.splide__list .splide__slide.is-active {
  opacity: 1;
  /* Hiển thị rõ phần tử có class is-active */
}

.progress {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rank .list-rank {
  border-bottom: 1px solid rgb(6 43 65);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* news User */

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

</style>  