<template>
  <div class="overflow-y-auto h-[100vh] md:overflow-hidden">
    <section>
      <div class="flex justify-between items-center py-20 px-10 bg-refer rounded-[2rem] relative mt-10">
        <div class="flex items-center">
          <div @click="changeAvatar">
            <img class="absolute top-10 h-[8rem] w-[8rem] rounded-[1.6rem]" :src="getAvatar(profile?.user_info?.avatar)"
              alt="" />
            <img class="absolute bottom-10 left-[9rem]" width="26" src="@/assets/images/pen-edit.png" alt="" />
            <!-- Hidden file input -->
            <input type="file" ref="fileInput" class="hidden" @change="onFileChange" />
          </div>
          <div class="ml-[10rem]">
            <h1 class="text-[2rem] font-bold">{{ profile?.user_info?.username }}</h1>
            <span class="text-secondary text-[1.7rem]">{{ profile?.user_info?.email }}</span>
          </div>
        </div>
  
  
        <div class="flex items-center">
          <!-- <button
            class="mr-5 flex items-center px-10 py-5 text-[1rem] font-bold bg-primary rounded-[1.6rem]"
          >
            <i class="fa-solid fa-wrench mr-5"></i> Edit profile
          </button>
  
          <button
            class="flex items-center px-10 py-5 text-[1rem] font-bold bg-primary rounded-[1.6rem]"
          >
            <i class="fa-solid fa-copy mr-5"></i> Change password
          </button> -->
  
          <button class="mr-5 flex items-center px-10 py-5 text-[1.3rem] font-bold bg-primary rounded-[1.6rem]"
            @click="saveAvatar()" v-if="this.avatarFile !== null">
            <i class="fa-solid fa-floppy-disk mr-2"></i>Save avatar
          </button>
  
        </div>
      </div>
    </section>
  
    <section class="md:flex justify-between">
      <div class="card rounded-[2rem] mt-10 px-10 py-10 md:w-[71%] md:h-[43rem] h-[40vh] w-[100%]">
        <div class="mb-10">
          <h1 class="text-[2rem] font-bold">Personal Infomation</h1>
          <p class="text-[1.6rem] text-secondary">
            Set up and review your personal information
          </p>
        </div>
  
        <div class="mt-5">
          <label class="text-[1.6rem] font-medium" for="name">Nickname</label>
          <input id="name" type="text" v-model="user_info.username"
            class="font-medium bg-[#042968] mt-5 w-full px-10 py-5 rounded-[1rem]" readonly />
        </div>
  
        <div class="mt-5">
          <label class="text-[1.6rem] font-medium" for="email">Email</label>
          <input id="email" type="text" v-model="user_info.email"
            class="font-medium bg-[#042968] mt-5 w-full px-10 py-5 rounded-[1rem]" readonly />
        </div>
  
        <div class="select-wrapper mt-5 max-h-[300px]">
          <label class="select text-[1.6rem] font-medium" for="email">Country</label>
          <v-select id="email" :options="countries" :reduce="c => c._id" label="name"
            class="font-medium bg-[#042968] mt-5 w-full px-10 py-5 rounded-[1rem] scroll text-white"
            v-model="user_info.country_id" disabled>
          </v-select>
  
        </div>
  
  
        <!-- <div class="text-center mt-10">
          <button class="px-20 py-4 border hover:bg-primary border-primary rounded-[1rem]">Cancel</button>
          <button class="ml-5 px-20 hover:bg-primary py-4 border border-primary rounded-[1rem]">Update</button>
        </div> -->
      </div>
  
  
  
      <div class="md:w-[28%] w-full mt-10 md:mt-10 h-[55vh]">
        <div class="bg-refer rounded-[2rem] px-5 py-5 relative text-center">
          <div class="pb-5" style="border-bottom: 1px solid rgb(171 182 189)">
            <h1 class="text-[2.4rem] font-semibold">Invite Friends and Earn Rewards!</h1>
            <div class="font-regular text-secondary leading-relaxed mb-3">
              <ul class="list-disc list-inside">
                <li>Earn $1 for each friend who signs up.</li>
                <li>Receive a percentage of their package purchases.</li>
              </ul>
            </div>
          </div>
          <ul class="list-disc list-inside">
          </ul>
          <div>
            <button @click="copyText(profile.referrer?.refer_code)"
              class="rounded-[1.6rem] p-5 bg-primary w-[60%] mt-5 font-semibold">
              Copy Referral Link
            </button>
          </div>
        </div>
        <div class="card rounded-[2rem] px-10 py-5 mt-10 flex flex-col justify-between items-center min-h-[12rem]">
          <div class="relative flex w-full">
            <img class="z-5 mx-auto" src="@/assets/images/Balance.png" alt="" />
            <div class="top-5 left-10 absolute z-10">
              <span class="text-[1.4rem] text-[#E9EDF7]">The total amount earned from referrals.</span>
              <h1 class="text-[2rem] mt-3 font-bold">$ {{ totalRefer() }}</h1>
            </div>
          </div>
  
          <!-- Conditional display based on totalRefer() -->
          <div v-if="this.new_user.length > 0" class="p-5 w-full">
            <transition name="fade" mode="out-in">
              <div :key="currentUser._id" class="flex items-center justify-between mt-5">
                <div class="flex items-center">
                  <span><i
                      class="px-5 py-6 mr-2 text-active bg-[#1A223F] rounded-[50%] text-[1.8rem] fa-solid fa-user-tie-hair"></i></span>
                  <div>
                    <h1 class="text-[1.6rem] font-medium py-1">New referral - {{ currentUser.user_reference_id.username }}
                    </h1>
                    <span class="font-medium text-secondary">{{ formattedDate(`${currentUser.createdAt}`) }}</span>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <Loading v-if="is_loading" />
    </section>

  </div>
</template>

<script>

import Loading from '@/components/Loading.vue';
import network from '@/network/network';
import { format } from "date-fns";

export default {
  components: { Loading },
  data() {
    return {
      is_loading: false,
      profile: {},
      user_info: {},
      countries: [],
      avatarSrc: '', // Default avatar image
      avatarFile: null,
      new_user: [],
      currentIndex: 0
    };
  },
  mounted() {
    this.getProfile();
    this.getListCountry();
    setInterval(this.nextUser, 5000);
  },
  computed: {
    currentUser() {
      return this.new_user[this.currentIndex];
    }
  },
  methods: {
    getAvatar(src) {
      if (this.avatarSrc) {
        return this.avatarSrc;
      }
      return network.getImg(src);
    },
    nextUser() {
      this.currentIndex = (this.currentIndex + 1) % this.new_user.length;
    },
    getProfile() {
      this.is_loading = true
      network.get('user/info').then(res => {
        const data = res.data.metaData;
        this.profile = data;
        this.user_info = data.user_info;
        this.new_user = data.referrer.new_user;
        this.is_loading = false;
      })
    },
    getListCountry() {
      this.is_loading = true

      network.get('countries').then(res => {
        this.countries = res.data.metaData;
        this.is_loading = false;

      }).catch(err => {
        console.log(err);
        this.is_loading = false;

      });
    },
    formatNumber(num) {
      return Number(num)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    totalRefer() {
      return this.formattedNumber(this.profile.referrer?.total_earned ?? 0) + "/" + this.profile.referrer?.total_user_referred
    },
    formattedNumber(num) {
      return Math.floor(num * 100) / 100
    },
    userRefer() {
      return this.formatNumber(this.profile?.referrer?.total_user_referred ?? 0);
    },
    changeAvatar() {
      this.$refs.fileInput.click();
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.avatarSrc = e.target.result; // Update the avatar source
        };
        reader.readAsDataURL(file);
        this.avatarFile = file; // Store the selected file
      }
    },
    copyText(text) {
      const currentUrl = window.location.origin;
      const textArea = document.createElement("textarea");
      textArea.value = currentUrl + '/r/' +  text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      this.$toast.open({
        message: "Copied",
        type: "success",
      });
    },
    formattedDate(timestamp) {
      const date = new Date(timestamp);
      if (isNaN(date)) {
        return "Invalid date";
      }
      return format(date, "dd MMMM yyyy, 'at' hh:mm a");
    },
    saveAvatar() {
      if (!this.avatarFile) {
        this.$toast.open({
          message: "Please select an image first",
          type: "warning",
        });
        return;
      }

      const formData = new FormData();
      formData.append('avatar', this.avatarFile);

      network.put(`/user/${localStorage.getItem('_id')}`, {}, formData)
        .then(res => {
          this.$toast.open({
            message: res.data.message,
            type: "success",
          });
          this.avatarFile = null
        })
        .catch(err => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
          });
        });
    }
  },
  name: "HomePage",
};
</script>

<style scoped>
::v-deep .vs__dropdown-menu {
  background: #042968;
  margin-top: 10px;
  border-radius: 5px;
}

::v-deep .v-select,
::v-deep .vs__selected {
  color: #fff;
}

::v-deep .vs--searchable .vs__dropdown-toggle {
  border: none;
  background: transparent;
}

::v-deep .vs__dropdown-menu::-webkit-scrollbar {
  width: 5px;
  /* Chiều rộng của thanh cuộn */

}

::v-deep .vs__dropdown-menu::-webkit-scrollbar-track {
  background-color: #161e5c;
  /* Màu nền của thanh cuộn */
}

::v-deep .vs__dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #21339b;
  /* Màu của nút thanh cuộn */
  border-radius: 6px;
  /* Độ cong của nút thanh cuộn */
}

::v-deep .vs__dropdown-menu::-webkit-scrollbar-thumb:hover {
  background-color: rgb(18, 17, 90);
  /* Màu của nút thanh cuộn khi hover */
}

::v-deep .vs__search {
  background: transparent !important;
  border: none;
}

::v-deep .vs__actions {
  display: none;
}

/* news User */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}
</style>