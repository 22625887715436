<template>
  <div class="flex justify-between px-5 py-5">
    <span class="text-[1.8rem] font-bold">Wallet balance transaction history</span>
    <span class="text-secondary flex items-center">
    </span>
  </div>
  <span class="flex pl-5 items-center text-blur">
    <i class="mr-2 text-active fa-solid fa-circle-check"></i>{{ this.historyIncome.length }} done this month
  </span>

<div class="scroll overflow-y-auto mb-5 pl-5 pr-5 lg:h-[33vh] 2xl:h-[32vh] h-[20vh] py-5 mt-5">
  <div class="flex items-center justify-between" v-for="i in historyIncome" :key="i">
    <div class="flex items-center">
      <div class="mr-5">
        <i
          class="px-[1rem] py-[0.8rem] rounded-[50%] text-md border fa-solid"
          :class="[{
            'text-yellow-500 border-yellow-500': i.status === 2,
            'text-blue-500 border-blue-500': i.type_id?.type === 1 || i.type_id?.type === 5 || i.type_id?.type === 6,
            'text-green-500 border-green-500': i.type_id?.type === 3,
            'text-red-500 border-red-500': i.type_id?.type === 2 || i.type_id?.type === 4,
            'text-light-blue-500 border-light-blue-500': i.type_id?.type === 7 || i.type_id?.type === 8,
            'text-red-500 border-red-500': i.status === 3
          },{
            'fa-exclamation-circle': i.status === 2,
            'fa-arrow-down': i.type_id?.adjustment === 1,
            'fa-arrow-up': i.type_id?.adjustment === 2,
            'fa-xmark': i.status === 3,
          }]"
        ></i>
      </div>
      <div>
        {{ i.type_id?.name }}
        <div class="text-secondary">{{ formattedDate(`${i.createdAt}`) }}</div>
      </div>
    </div>
    <div :class="{
      'text-yellow-500': i.status === 2,
      'text-blue-500': i.type_id?.type === 1 || i.type_id?.type === 5 || i.type_id?.type === 6,
      'text-green-500': i.type_id?.type === 3,
      'text-red-500': i.type_id?.type === 2 || i.type_id?.type === 4,
      'text-light-blue-500': i.type_id?.type === 7 || i.type_id?.type === 8
    }">
      {{ i.type_id?.adjustment === 1 ? '+' : '-' }} ${{ i.amount }}
    </div>
  </div>
</div>

</template>

<script>
import network from "@/network/network";
import { format } from 'date-fns';
export default {
  name: "historyRewards",

  data() {
    return {
      historyIncome: [1, 2, 3, 4, 5, 6, 7],
    };
  },
  mounted() {
    this.getRewards();
  },
  methods: {
    getRewards() {
      network
        .get("transactions/history")
        .then((res) => {
          this.historyIncome = res.data.metaData;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPackageId(i) {
      return i.remaining_packages_id ? i.remaining_packages_id._id : '';
    },
    formattedDate(timestamp) {
      const date = new Date(timestamp);
      if (isNaN(date)) {
        return 'Invalid date';
      }
      return format(date, "dd MMMM yyyy, 'at' hh:mm a");
    }
  }
};
</script>

<style></style>