<template>
    <div class="p-6 h-full">
        <div class="flex ">
            <Sidebar />
            <div class="w-full pb-6 relative">
                <Header />
                <router-view></router-view>
            </div>
        </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
import Header from '@/components/Header.vue';
export default {
    components: { Sidebar, Header },
    name: 'HomePage'
}
</script>

<style>

</style>