<template>
  <div class="md:w-auto sm:w-0 lg:w-auto w-0">
    <div v-if="isOpen" @click="isOpen = false" class="sm:hidden fixed inset-0 flex items-center justify-center bg-slate-900 bg-opacity-90 z-40"></div>

    <!-- Nút menu cho mobile -->
    <button @click="toggleSidebar" class="md:hidden fixed top-0 right-5 z-50 mt-5 bg-violet-800 text-white px-5 py-3 rounded-md">
      <i class="fa-solid fa-bars-staggered text-2xl font-bold"></i>
    </button>

    <div
      :class="{
        'translate-x-0': isOpen,
        '-translate-x-full': !isOpen
      }"
      class="fixed md:relative top-0 left-0 h-full transition-transform duration-300 ease-in-out md:translate-x-0 z-40"
    >
      <div
        @mouseover="handleMouseOver"
        @mouseleave="handleMouseLeave"
        :class="[
          hovered === 'show' ? 'w-96' : 'w-20',
          'card rounded-[2rem] mr-6 w-96 h-[100vh] sm:h-[97vh] '
        ]"
        class="hover:cursor-pointer relative h-full transition-all duration-300 ease-in-out"
      >
        <div class="text-center">
          <h1 class="uppercase p-[2.4rem] text-[1.4rem] whitespace-nowrap overflow-hidden">
            Miner online
          </h1>
        </div>
        <div>
          <ul class="mt-3 p-5">
            <li @click="toPath('/')" :class="{ active: $route.path === '/' }">
              <i class="fa-solid fa-house-chimney"></i>
              <span :class="{ 'hidden md:inline': hovered !== 'show' }">Home</span>
            </li>
            <li @click="toPath('/wallet')" :class="{ active: $route.path === '/wallet' }">
              <i class="fa-solid fa-wallet"></i>
              <span :class="{ 'hidden md:inline': hovered !== 'show' }">Wallet</span>
            </li>
            <li @click="toPath('/machines')" :class="{ active: $route.path === '/machines' }">
              <i class="fa-solid fa-robot"></i>
              <span :class="{ 'hidden md:inline': hovered !== 'show' }">Machines</span>
            </li>
            <li @click="toPath('/rank')" :class="{ active: $route.path === '/rank' }">
              <i class="fa-solid fa-chart-simple"></i>
              <span :class="{ 'hidden md:inline': hovered !== 'show' }">Ranking</span>
            </li>
            <li @click="toPath('/profile')" :class="{ active: $route.path === '/profile' }">
              <i class="fa-solid fa-user"></i>
              <span :class="{ 'hidden md:inline': hovered !== 'show' }">Profile</span>
            </li>
          </ul>
          <ul class="p-3">
            <li @click="logout">
              <i class="fa-solid fa-right-from-bracket"></i>
              <span :class="{ 'hidden md:inline': hovered !== 'show' }">Log out</span>
            </li>
          </ul>
        </div>

        <div class="refer-bg absolute bottom-5 p-5 rounded-[2rem] mx-auto left-0 right-0 w-[90%]">
          <div class="text-center relative bottom-5">
            <span 
              class="px w-[3rem] flex justify-center py-2.5 bg-white rounded-2xl absolute left-0 explane-refer"
              @mouseenter="showTooltip = true"
              @mouseleave="showTooltip = false"
            >
              <div 
                v-if="showTooltip"
                class="absolute md:w-[500px] w-[350px] left-[50%] z-50 text-white rounded py-1 px-4 bottom-full mb-2 whitespace-normal tooltip"
                role="tooltip"
              >
                <div class="bg-refer rounded-[2rem] px-5 py-5 relative text-center shadow-lg">
                  <div class="pb-5 mb-5" style="border-bottom: 1px solid rgb(171 182 189)">
                    <h1 class="text-[2.4rem] font-semibold mb-3">Invite Friends and Earn Rewards!</h1>
                    <div class="font-regular text-secondary leading-relaxed">
                      Share the joy of our platform with your friends and earn exciting rewards. For every friend you refer, you'll receive $1 as a thank you. But that's not all! You will also earn a percentage of the amount your friends spend on purchasing packages.
                    </div>
                  </div>
                  <div class="text-left font-regular text-secondary leading-relaxed mb-5">
                    <p><strong>Here's how it works:</strong></p>
                    <ol class="list-decimal list-inside">
                      <li>Copy your unique referral link and share it with your friends.</li>
                      <li>When your friends sign up using your link and make a purchase, you instantly earn $1.</li>
                      <li>Additionally, you'll receive a percentage of the amount your friends spend on any package they buy.</li>
                    </ol>
                    <p>Start inviting your friends today and watch your rewards grow!</p>
                  </div>
                </div>
              </div>
              <i class="fa-solid text-[1rem] fa-question px-2 py-1.5 bg-primary rounded-[50%]"></i>
            </span>
            <div class="text-center">
              <h1 class="text-[1.2rem] font-semibold py-2 mt-5 ml-5">Invite Friends and Earn Rewards!</h1>
              <div class="text-[3rem] font-semibold money-refer relative mb-5 flex items-center justify-center">
              </div>
              <button @click="copyText(profile.referrer?.refer_code)" class="w-full rounded-[1rem] text-[1.4rem] my-1 font-semibold bg-primary px-2 py-3 flex items-center justify-center">
                Copy Referral Link <i class="fa-solid fa-arrow-trend-up ml-2"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import network from '@/network/network';
export default {
  name: "sideBar",
  data() {
    return {
      hovered: null,
      isOpen: false,
      showTooltip: false,
      profile: {},
    };
  },
  mounted() {
    this.checkScreenSize();
    this.getProfile()
    window.addEventListener("resize", this.checkScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  methods: {
    toPath(path) {
      this.$router.push(path);
      this.isOpen = false;
    },
    logout() {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('_id');
      localStorage.removeItem('email');
      localStorage.removeItem('role');
      this.$router.push('/login');
    },
    checkScreenSize() {
      if (window.innerWidth >= 768) {
        this.hovered = "show";
        this.isOpen = true;
      } else {
        this.hovered = null;
        this.isOpen = false;
      }
    },
    handleMouseOver() {
      if (window.innerWidth >= 768) {
        this.hovered = "show";
      }
    },
    handleMouseLeave() {
      if (window.innerWidth >= 768) {
        this.hovered = null;
      }
    },
    toggleSidebar() {
      this.isOpen = !this.isOpen;
    },
    getProfile() {
      network.get('user/info').then(res => {
        const data = res.data.metaData;
        this.profile = data;
      })
    },
    copyText(text) {
      const currentUrl = window.location.origin;
      const textArea = document.createElement("textarea");
      textArea.value = currentUrl + '/r/' +  text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      this.$toast.open({
        message: "Copied",
        type: "success",
      });
    },
  },
};
</script>

<style scoped>
ul {
  position: relative;
}

ul::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(to right, transparent, white, transparent);
}

ul li {
  margin: 1.6rem 0;
  padding: 10px;
  border-radius: 1.5rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

ul li:hover {
  background: #05173d;
  cursor: pointer;
}

ul li i {
  background: #05173e;
  padding: 10px;
  border-radius: 1.6rem;
  color: #0175ff;
  min-width: 40px;
  text-align: center;
}

ul li:hover i {
  color: #fff;
}

ul li span {
  margin-left: 10px;
}

.active {
  background: #05173e;
  color: #fff;
}

.active i {
  color: white;
  background: #0075ff;
}

.money-refer::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(to right, transparent, white, transparent);
}

.tooltip {
  transform: translateX(10%);
  transform: translateY(-5%);
  transition: visibility 0.3s, opacity 0.3s;
}

@media (max-width: 767px) {
  .card {
    width: 250px;
    min-width: 250px;
  }
  
  ul li span {
    display: inline;
  }
}

@media (min-width: 768px) {
  .card {
    transition: width 0.3s ease-in-out;
  }
}
</style>