<template>
  <div class="pr-5 mt-10 max-h-[auto] overflow-auto scroll" style="max-height: calc(100vh - 11.6rem)">
    <FlucuaInfo :profile="profile" />

    <div class="mt-5  rank w-[100%] box px-10 py-5 h-[100%]">
      <div class="flex justify-between my-2">
        <div class="text-[1.8rem] font-bold">
          <!-- Bảng xếp hạng người dùng theo lợi nhuận -->
          Ranking
        </div>
        <div class="md:w-[25%] w-[40%]">
          <div class="text-secondary flex items-center justify-between">
            My rank:

            <span class="text-primary text-[16px] font-semibold flex items-center">
              #{{ userRankings.my_rank }}
            </span>
          </div>
          <div class="text-secondary flex items-center justify-between">
            Total users joined:

            <span class="md:text-[1.6rem] text-[1.6rem] font-semibold flex items-center">
              #{{ userRankings.total_user }}
            </span>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-between list-rank p-2 pt-4 pb-5">
        <div class="w-[1%]"></div>
        <div class="w-[15%] text-center">User</div>
        <div class="w-[20%] text-center hidden md:block">Email</div>
        <div class="w-[10%] text-center hidden md:block">Country</div>

        <div class="w-[10%] text-center  ">Daily rewards </div> 
        <div class="w-[5%] text-center hidden md:block">Joined</div>
        <div class="w-[10%] text-center ">Rented Machines</div>
        <div class="w-[15%] text-center">Profit</div>
      </div>
      <div class="max-h-[auto] overflow-y-auto scroll">
        <div @click="overview(i._id)"
          class="hover:bg-[#042968] flex items-center justify-between list-rank p-2 pt-5 hover:cursor-pointer  py-5"
          v-for="(i, index) in userRankings.ranking" :key="index">
          <div class="w-[1%]  flex justify-center font-bold mr-2"
            :class="index > 2 ? 'text-primary text-[2.4rem]' : 'text-[3.2rem]'">
            {{ index + 1 }}
          </div>
          <div class="w-[15%] flex items-center justify-center">
            <img class="mr-5 h-[4rem] w-[4rem] rounded-md" :src="getAvatar(i.avatar)" alt="" />
            <h1 class="ml-2">{{ i.username }}</h1>
          </div>
          <div class="w-[20%] text-[1.6rem] text-secondary hidden md:block text-center">
            {{ i.email }}
          </div>
          <div class=" text-center items-center justify-center w-[10%] hidden md:flex">
            <img width="22.5rem" class="h-[1.6rem] mr-2" :src="getCountry(i.country_id)?.flag" alt="" />
            <h1 class="ml-2">{{ getCountry(i.country_id)?.name }}</h1>
          </div>
          <div class="w-[10%] text-center text-[1.6rem]">{{ i.daily_rewards }}</div>
          <div class="w-[5%] text-center hidden md:block">{{ formatDate(i.createdAt) }}</div>
          <div class="w-[10%] text-center">
            <div>Solo: {{ i.solo_count }}</div>
            <div>Pool: {{ i.pool_count }}</div>
          </div>
          <div :class="index > 2 ? 'text-secondary text-[1.8rem]' : 'md:text-[2.4rem] text-[1.4rem] '"
            class="w-[15%] text-center font-bold ">${{ formattedNumber(i?.total_earned) }}</div>
        </div>
      </div>
    </div>
  </div>
  <Loading v-if="is_loading" />
</template>

<script>
import network from '@/network/network';
import dayjs from 'dayjs';
import Loading from '@/components/Loading.vue';
import FlucuaInfo from '@/components/FlucuaInfo.vue';
export default {
  components: { Loading, FlucuaInfo },
  data() {
    return {
      invests: [1, 2, 3, 4],
      sliders: [1, 2, 3, 4, 5],
      countries: [],
      is_loading: false,
      profile: {},
      id_actived: 0,
      options: {
        rewind: true,
        // autoplay: true,
        padding: "10%",
        type: "loop",
        arrows: false,
      },
      userRankings: [],
      dataTable: [1, 2, 3, 4, 5, 6, 7, 8],
      historyIncome: [1, 2, 3, 4, 5, 6],
    };
  },
  name: "HomePage",
  mounted() {
    this.getUserRanking();
    this.getListCountry();
  },
  methods: {
    getAvatar(src) {
      return network.getImg(src);
    },
    getUserRanking() {
      this.is_loading = true;
      network
        .get("user/ranking")
        .then((res) => {
          let data = res.data.metaData;
          this.userRankings = data;
          this.is_loading = false;
          this.id_actived = data.ranking[0]._id;
          this.getProfileAccountOverview(data.ranking[0]._id)
        })
        .catch((err) => {
          console.log(err);
          this.is_loading = false;
        });
    },
    getProfileAccountOverview() {
      // this.id_actived = id;
      this.is_loading = true;
      network.get("/user/overview/" + localStorage.getItem('_id')).then((res) => {
        const data = res.data.metaData;
        this.profile = data;
        // this.calculator(data.remaining_packages)
        this.is_loading = false;

      }).catch((e) => {
        console.log(e);
        this.is_loading = false;
      });
    },
    getListCountry() {
      this.is_loading = true;
      network.get('countries').then(res => {
        this.countries = res.data.metaData;
        this.is_loading = false;
      }).catch(err => {
        console.log(err);
      });
    },
    formatDate(date) {
      return dayjs(date).format('DD/MM/YYYY');
    },
    getCountry(id_country) {
      const country = this.countries.find(c => c._id == id_country);
      return country;
    },
    formattedNumber(number) {
      // Format using toLocaleString
      return number.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    overview(id) {
      this.$router.push({ path: '/overview/' + id });
    }

  },
};
</script>

<style scoped>
.rank .list-rank {
  border-bottom: 1px solid rgb(6 43 65);
}
</style>