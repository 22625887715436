<template>
  <div class="container mx-auto p-4">
    <h1 class="text-2xl font-bold mb-4 text-black">User Management</h1>
    <div class="bg-gray-800 shadow-md rounded my-6 p-4">
      <div class="mb-4">
        <input
          v-model="newUser.name"
          type="text"
          placeholder="Name"
          class="border p-2 mr-2 bg-gray-700 text-white"
        />
        <input
          v-model="newUser.email"
          type="text"
          placeholder="Email"
          class="border p-2 mr-2 bg-gray-700 text-white"
        />
        <button @click="addUser" class="bg-blue-500 text-white p-2 rounded">
          Add User
        </button>
      </div>
      <DynamicTable
        :data="users"
        :columns="columns"
        :actions="tableActions"
      />
    </div>
    <div v-if="isEditing" class="bg-gray-900 p-4 rounded mt-4 text-white">
      <h2 class="text-xl font-bold mb-4">Edit User</h2>
      <input
        v-model="currentUser.name"
        type="text"
        placeholder="Name"
        class="border p-2 mr-2 bg-gray-700 text-white"
      />
      <input
        v-model="currentUser.email"
        type="text"
        placeholder="Email"
        class="border p-2 mr-2 bg-gray-700 text-white"
      />
      <button @click="updateUser" class="bg-green-500 text-white p-2 rounded">
        Update
      </button>
      <button @click="cancelEdit" class="bg-gray-500 text-white p-2 rounded ml-2">
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import DynamicTable from '@/components/DynamicTable.vue';

export default {
  name: 'WalletApp',
  components: {
    DynamicTable
  },
  data() {
    return {
      users: [
        { name: 'John Doe', email: 'john@example.com' },
        { name: 'Jane Doe', email: 'jane@example.com' }
      ],
      newUser: {
        name: '',
        email: ''
      },
      currentUser: null,
      currentIndex: null,
      isEditing: false,
      columns: [
        { key: 'name', label: 'Name' },
        { key: 'email', label: 'Email' }
      ],
      tableActions: [
        {
          label: 'Edit',
          handler: this.editUser,
          class: 'bg-yellow-500 text-white'
        },
        {
          label: 'Delete',
          handler: this.deleteUser,
          class: 'bg-red-500 text-white'
        }
      ]
    }
  },
  methods: {
    addUser() {
      if (this.newUser.name && this.newUser.email) {
        this.users.push({ ...this.newUser });
        this.newUser.name = '';
        this.newUser.email = '';
      }
    },
    editUser(index) {
      this.currentUser = { ...this.users[index] };
      this.currentIndex = index;
      this.isEditing = true;
    },
    updateUser() {
      if (this.currentUser.name && this.currentUser.email) {
        this.$set(this.users, this.currentIndex, this.currentUser);
        this.cancelEdit();
      }
    },
    deleteUser(index) {
      this.users.splice(index, 1);
    },
    cancelEdit() {
      this.currentUser = null;
      this.currentIndex = null;
      this.isEditing = false;
    },
  }
}
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
th, td {
  text-align: left;
  padding: 8px;
}
th {
  background-color: #2d3748;
}
</style>
